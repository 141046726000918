import React from 'react';
import Logo from '../assets/logo.svg';
import '../styles.scss';

const Header = ({ style }) => {
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="col-5 col-md-3">
            <a href="/">
              <img src={Logo} alt="logo" />
            </a>
          </div>
          <div className="col-1 col-md-6"></div>
          <div className="col-6 col-md-3 header-btn">
            <a href="#downloadapp">
              <button className="download-button" style={style}>
                Download <i className="mdi mdi-chevron-right" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
