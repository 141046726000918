import React from 'react';
import GooglePlayBtn from '../assets/google-play-btn.svg';
import GooglePlayWhite from '../assets/google-play-white-btn.svg';
import AppStoreBtnWhite from '../assets/ios-app-badge-white.svg';
import AppStoreBtn from '../assets/ios-app-badge.svg';

const { REACT_APP_GOOGLE_PLAY_URL, REACT_APP_APPSTORE_URL } = process.env;

const AppButtons = (props) => {
  return (
    <div className="app-buttons">
      <a href={REACT_APP_GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
        <img
          src={props.top ? GooglePlayBtn : GooglePlayWhite}
          className="img-fluid app"
          alt="google-play"
        />
      </a>

      <a href={REACT_APP_APPSTORE_URL} target="_blank" rel="noreferrer">
        <img
          src={props.top ? AppStoreBtn : AppStoreBtnWhite}
          className="img-fluid app"
          alt="mobile-use"
        />
      </a>
    </div>
  );
};

export default AppButtons;
