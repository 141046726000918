import React, { useEffect } from 'react';
import './styles.scss';
import Logo from './assets/logo.svg';
import PhoneOneSvg from './assets/img-phone.png';
import PZ from './assets/brands/pz.svg';
import Vaseline from './assets/brands/vaseline.svg';
import Pepsodent from './assets/brands/pepsodent.svg';
import MorningFresh from './assets/brands/morning-fresh.svg';
import Cerelac from './assets/brands/cerelac.svg';
import Maggi from './assets/brands/maggi.svg';
import Cowbell from './assets/brands/cowbell.svg';
import Kellogs from './assets/brands/kellogs.svg';
import PhoneTwoSvg from './assets/phone-img-2.svg';
import PhoneThreeSvg from './assets/phone-img-3.svg';
import CardSvg from './assets/card-img.svg';
import { DropLists } from './components/dropdowns';
import BrandRectTop from './assets/header-bend.svg';
import BrandRectBottom from './assets/header-bend-2.svg';
import AppButtons from './components/app-buttons';
import Header from './components/header';

const { REACT_APP_SELL_URL, REACT_APP_PAY_URL } = process.env;

export const HomeLanding = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    var ua = navigator.userAgent;
    // Check the user-agent string to identyfy the device.
    const isMobile =
      /mobile|iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile|ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(
        ua,
      );
    setIsMobile(isMobile);
  }, []);

  return (
    <div className="shoptopup-landing-design">
      <Header />
      <div className="introduction">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img
                src={PhoneOneSvg}
                alt="phone-one"
                className="img-phone-one img-fluid"
              />
            </div>
            <div className="col-12 col-md-6 introduction-text">
              <h1>Buy Now Now,</h1>
              <h1>Pay Small Small</h1>
              <h6>
                Hundreds of thousands of retailers of all sizes across Africa
                use ShopTopUp to get access to business loans and inventory to
                stock up their shops daily. Get started today.
              </h6>
              <AppButtons isMobile={isMobile} top={true} />
            </div>
          </div>
        </div>
      </div>

      <div className="brands-container">
        <img
          src={BrandRectTop}
          className="img-fluid curve top"
          alt="bend-top"
        />
        <div className="brands">
          <div className="container">
            <div className="row brands-list">
              <div className="col-12 col-md-4">
                <h3>Never run out of Stock.</h3>
                <h6>
                  Restock your store from your mobile phone in a single place
                  with access to inventory from over 100 manufacturers &
                  distributors.
                </h6>
              </div>
              <div className="col-12 col-md-7">
                <div className="brand-logos">
                  <p>
                    <img src={PZ} alt="pz-logo" className="img-fluid" />
                  </p>
                  <p>
                    <img
                      src={Vaseline}
                      alt="vaseline-logo"
                      className="img-fluid"
                    />
                  </p>
                  <p>
                    <img
                      src={Pepsodent}
                      alt="pepsodent-logo"
                      className="img-fluid imgx3"
                    />
                  </p>
                  <p>
                    <img
                      src={MorningFresh}
                      alt="morning-fresh-logo"
                      className="img-fluid"
                    />
                  </p>
                  <br />
                  <p>
                    <img
                      src={Cerelac}
                      alt="cerelac-logo"
                      className="img-fluid"
                    />
                  </p>
                  <p>
                    <img
                      src={Maggi}
                      alt="maggi-logo"
                      className="img-fluid imgx1"
                    />
                  </p>
                  <p>
                    <img
                      src={Cowbell}
                      alt="cowbell-logo"
                      className="img-fluid"
                    />
                  </p>
                  <p>
                    <img
                      src={Kellogs}
                      alt="kellogs-logo"
                      className="img-fluid imgx2"
                    />
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <img
          src={BrandRectBottom}
          className="img-fluid curve bottom"
          alt="bend-bottom"
        />
      </div>

      <div className="shop-capital info-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="info-image">
                <img
                  src={PhoneTwoSvg}
                  alt="shop-capital-phone"
                  className="img-fluid"
                />
              </p>
            </div>
            <div className="col-12 col-md-6 info-text">
              <h1>Never run out of capital for your shop.</h1>
              <h6>
                Get a convenient loan from as low as 50,000 naira to make sure
                you never run out of items to sell and help you grow your
                business. We offer low interest rates and repayment times
                tailored to your needs.
              </h6>
              <div className="list ">
                <li>
                  <span className="mdi mdi-check" />
                  Loans from N50,000 - N500,000
                </li>
                <li>
                  <span className="mdi mdi-check" />
                  Flexible Repayment Periods
                </li>
                <li>
                  <span className="mdi mdi-check mdi-bold" />
                  Low affordable interest rates
                </li>
              </div>

              <p className="action">
                <a
                  href="https://play.google.com/store/apps/details?id=co.tradedepot.shop&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get a loan <i className="mdi mdi-chevron-right" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="inventory info-section left">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 info-text">
              <h1>Fulfill all your inventory needs in one place.</h1>
              <h6>
                ShopTopUp partners with distributors and manufacturers to make
                sure that all your inventory needs are met in one central place,
                from the comfort of your phone, just order your items, pay and
                get everything delivered to you at once.
              </h6>
              <div className="list">
                <li>
                  <span className="mdi mdi-check" />
                  Buy in bulk
                </li>
                <li>
                  <span className="mdi mdi-check" />
                  Lowest unit rates
                </li>
                <li>
                  <span className="mdi mdi-check mdi-bold" />
                  Delivered to your shop
                </li>
              </div>

              <p className="action">
                <a
                  href="https://play.google.com/store/apps/details?id=co.tradedepot.shop&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Start buying <i className="mdi mdi-chevron-right" />
                </a>
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p className="info-image">
                <img src={PhoneThreeSvg} alt="inventory-phone" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="cashless-suppliers info-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <p className="info-image">
                <img src={CardSvg} alt="cashless-suppliers-phone" />
              </p>
            </div>
            <div className="col-12 col-md-6 info-text">
              <h1>Go cashless with your suppliers.</h1>
              <h6>
                With a virtual wallet and credit card with which suppliers can
                collect payments, you never need to worry about holding loads of
                cash in hand when it's time to pay.
              </h6>
              <div className="list">
                <li>
                  <span className="mdi mdi-check" />
                  Credit Card for your virtual wallet
                </li>
                <li>
                  <span className="mdi mdi-check" />
                  No transaction fees
                </li>
                <li>
                  <span className="mdi mdi-check mdi-bold" />
                  Instant transaction confirmation
                </li>
              </div>

              <p className="action">
                <a
                  href="https://play.google.com/store/apps/details?id=co.tradedepot.shop&hl=en_US&gl=US"
                  target="_blank"
                  rel="noreferrer"
                >
                  Go cashless <i className="mdi mdi-chevron-right" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="info-retailers">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1"></div>
            <div
              className="col-12 col-md-10"
              data-bs-spy="scroll"
              id="downloadapp"
            >
              <div className="retailers-text">
                <h1>Join 100,000+ Retailers</h1>
                <h6>
                  Thousands of retailers around Africa already use ShopTopUp to
                  power their businesses. Get started today.{' '}
                </h6>
                <AppButtons isMobile={isMobile} />
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>

      <div className="shoptopup-faqs">
        <div className="container">
          <h2>ShopTopUp FAQs</h2>
          <DropLists />
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <img src={Logo} alt="logo-" className="img-fluid" />
              <p className="copyright-text">
                &copy; {new Date().getFullYear()} ShopTopUp
              </p>
            </div>
            <div className="col-2"></div>
            <div className="col-md-4 col-12">
              <div className="footer-menu">
                <a href={REACT_APP_PAY_URL}>
                  <li>Pay</li>
                </a>
                <a href={REACT_APP_SELL_URL}>
                  <li>Distributors</li>
                </a>
                <a href="/terms">
                  <li>Terms</li>
                </a>
                <a href="/privacy">
                  <li>Privacy</li>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
