import React from 'react';

export const CarType = ({ img, carname }) => {
  return (
    <div className="stu-drive_section-three_cars_wrapper">
      <h5 className="stu-drive_section-three_cars_wrapper_name">{carname}</h5>
      <img src={img} className="img-fluid" alt="megane-car" />
    </div>
  );
};
