import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HomeLanding } from './homepage';
import Terms from './terms';
import Privacy from './privacy';
import Drive from './drive';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route component={HomeLanding} path="/" exact />
        <Route component={Drive} path="/drive" exact />
        <Route component={Terms} path="/terms" exact />
        <Route component={Privacy} path="/privacy" exact />
      </Switch>
    </Router>
  );
};

export default Routes;
