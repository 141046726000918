import React from 'react';

export const DropLists = () => {
  return (
    <div className="dropdown-list">
      <div className="drop-col">
        <a
          data-bs-toggle="collapse"
          href="#whatishoptopup"
          role="button"
          aria-expanded="false"
          aria-controls="whatishoptopup"
        >
          What is ShopTopUp?
          <i className="mdi mdi-chevron-down" />
        </a>
        <div class="collapse" id="whatishoptopup">
          <p>
            ShopTopUp is the platform that helps you grow your retail business
            quickly by giving you instant loans to stock up your shop. You can
            also buy products at the best prices directly from manufacturers
            right from the ShopTopUp App.
          </p>
        </div>
      </div>
      <div className="drop-col">
        <a
          data-bs-toggle="collapse"
          href="#gettingstarted"
          role="button"
          aria-expanded="false"
          aria-controls="gettingstarted"
        >
          How do I get started?
          <i className="mdi mdi-chevron-down" />
        </a>
        <div class="collapse" id="gettingstarted">
          <p>
            To get started, click{' '}
            <a
              href="https://play.google.com/store/apps/details?id=co.tradedepot.shop&hl=en_US&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            to download the ShopTopUp app on Google Play Store and sign up now.
            After you have logged in, click on loans to apply for an instant
            loan.
          </p>
        </div>
      </div>
      <div className="drop-col">
        <a
          data-bs-toggle="collapse"
          href="#purchaseItems"
          role="button"
          aria-expanded="false"
          aria-controls="purchaseItems"
        >
          What type of Items can I purchase on ShopTopUp
          <i className="mdi mdi-chevron-down" />
        </a>
        <div class="collapse" id="purchaseItems">
          <p>
            You can buy a wide range of goods, over 200 brands, in different
            categories such as Foodstuff, Drinks, Toiletries, Detergents, Baby
            Care.
          </p>
        </div>
      </div>
      <div className="drop-col">
        <a
          data-bs-toggle="collapse"
          href="#accessloans"
          role="button"
          aria-expanded="false"
          aria-controls="accessloans"
        >
          What do I need to access loans on ShopTopUp ?
          <i className="mdi mdi-chevron-down" />
        </a>
        <div class="collapse" id="accessloans">
          <p>
            As a retail business owner, all you need to get an instant loan from
            ShopTopUp is the ShopTopUp app. Click{' '}
            <a
              href="https://play.google.com/store/apps/details?id=co.tradedepot.shop&hl=en_US&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            to download the ShopTopUp app on Google Play Store and get started.
            Complete your application on the app and your ShopTopUp wallet will
            be automatically credited.
          </p>
        </div>
      </div>
      <div className="drop-col">
        <a
          data-bs-toggle="collapse"
          href="#borrowlength"
          role="button"
          aria-expanded="false"
          aria-controls="borrowlength"
        >
          How much can I borrow and for how long?
          <i className="mdi mdi-chevron-down" />
        </a>
        <div class="collapse" id="borrowlength">
          <p>
            You can borrow between ₦50,000 - ₦500,000 with a loan period of
            either 14 days or 28 days. You can also choose to repay the loan
            either on a weekly or a bi-weekly basis.
          </p>
        </div>
      </div>
      <div className="drop-col">
        <a
          data-bs-toggle="collapse"
          href="#getloan"
          role="button"
          aria-expanded="false"
          aria-controls="getloan"
        >
          Do I need collateral to get a loan from ShopTopUp ?
          <i className="mdi mdi-chevron-down" />
        </a>
        <div class="collapse" id="getloan">
          <p>No collateral is required to get a loan from ShopTopUp.</p>
        </div>
      </div>
    </div>
  );
};
