import React from 'react';
import Header from './components/header';
import './styles.scss';

const Terms = () => {
  const [activeId, setActive] = React.useState('definition');
  // const fixedHeaderButtonStyle = {
  //   position: 'fixed',
  //   top: '15px',
  //   right: '70px',
  //   zIndex: '999',
  // };
  return (
    <div className="terms">
      {/* <Header style={fixedHeaderButtonStyle} /> */}
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-12" id="introduction">
            <div className="title">
              <h5>TERMS OF USE</h5>
            </div>
            <div className="introduction">
              <span>
                This Terms of Use (<strong>"Terms of Use"</strong>) is a legal
                agreement between you (<strong>"You"</strong>/{' '}
                <strong>"Your"</strong>/ <strong>"TradeDepot Retailer"</strong>
                ), and TradeDepot (<strong>"TradeDepot"</strong>{' '}
                <strong>"Us"</strong> <strong>"Our"</strong>{' '}
                <strong>"We"</strong>). This Terms of Use (together with the
                Cash Advance Terms of Use and our Privacy Policy for the App,
                referred to as the <strong>"Related Agreements"</strong>) sets
                out the complete terms and conditions upon which You may use the
                ShopTopUp application software (the <strong>"App"</strong>) and
                website hosted at www.shoptopup.com (the{' '}
                <strong>"“Website”"</strong>).
              </span>
              <br /> <br />
              <span>
                By signing up to create a profile (
                <strong>"ShopTopUp Profile"</strong>), You acknowledge that You
                have read, understood, and agree to be bound by this Terms of
                Use, and the Related Agreements, where applicable. You also
                agree to comply with all agreements, applicable laws, and
                regulations incorporated into this Terms of Use. The provisions
                of this Terms of Use and the Related Agreements govern Your use
                of the App, and the business relationship between You and
                TradeDepot. If You do not accept the terms of this Agreement and
                other Related Agreements, do not access or use this site or the
                services.
              </span>
              <br /> <br />
              <span>
                The App allows a TradeDepot Retailer to purchase a stock of
                Consumer Goods (the <strong>"Products"</strong>). The App also
                allows TradeDepot Retailers to request and obtain cash advances
                from TradeDepot in order to enable a TradeDepot Retailer use
                such cash advances granted by TradeDepot for the sole purpose of
                purchasing Products on the App or from registered ShopTopUp pay
                partners (<strong>"Pay Partners"</strong>) collectively referred
                to as (<strong>"Services"</strong>). Any new features or tools
                which are added to the Services shall be also subject to the
                Terms of Use.
              </span>
              <br /> <br />
              <span>
                TradeDepot reserves the right to amend this Terms of Use and the
                Related Agreements at any time and will notify You of any such
                changes by posting the revised terms of use on Website and on
                the App. All changes shall be effective upon posting. You should
                check this Terms of Use and the Related Agreements periodically
                for such changes. Your continued use of the App after any such
                changes to this Terms of Use and Related Agreements constitutes
                Your agreement to be bound by any changes. TradeDepot may
                terminate, suspend, change, or restrict access to all or any
                part of the Services without notice or liability.
              </span>
              <br /> <br />
              <span>
                You acknowledge and agree that Your use of the Services,
                including information transmitted to or stored by TradeDepot, is
                governed by Our Privacy Policy.
              </span>
            </div>
            <br /> <br />
            <div className="contract-section" id="profile-terms">
              <h5 className="section-title agreed">
                1. ShopTopUp Profile Terms
              </h5>
              <span className="contract-text">
                1.1 &nbsp; You hereby declare that You are a human being over 18
                years of age and of a sound mind, or at least the age of
                majority in the jurisdiction where You reside and from which You
                use the Services. Your ShopTopUp Profile may be deleted without
                warning, if We believe, in Our sole discretion, that You are
                less than 18 years of age.
                <br /> <br />
                1.2 &nbsp; In order to create a ShopTopUp Profile and access the
                Services, You would be required to download the App and click on
                the “sign up” tab. Alternatively, You may sign up for a
                ShopTopUp Profile by registering through a verified ShopTopUp
                field representative (<strong>Verified Representative</strong>).
                <br /> <br />
                1.3 &nbsp; In order to create a ShopTopUp Profile on the App or
                through a Verified Representative, You would be required to
                provide the following: Your first name, last name, phone number,
                business name, password, business address, date of birth, and
                any other information indicated as required or that We may ask
                from You from time to time (<strong>"Information"</strong>).
                <br /> <br />
                1.4 &nbsp; When You create a ShopTopUp Profile, You will be
                deemed as the contracting party and a TradeDepot Retailer for
                the purposes of this Terms of Use.
                <br /> <br />
                1.5 &nbsp; We reserve the right to deactivate Your ShopTopUp
                Profile, on suspicion of illegal activities or other activities
                that directly or indirectly breaches the provisions of this
                Terms of Use and the Related Agreements or for no reason at all,
                in Our sole discretion.
                <br /> <br />
                1.6 &nbsp; You acknowledge that TradeDepot will use the phone
                number You provided to Us when You created Your ShopTopUp
                Profile or as updated by You from time to time as the primary
                mode of communicating with You. You must monitor the ShopTopUp
                Profile phone number You provided to TradeDepot. Your ShopTopUp
                Profile phone number must be capable of making calls and
                receiving calls. Your communications with TradeDepot can only be
                authenticated if they come from Your ShopTopUp Profile phone
                number.
                <br /> <br />
                1.7 &nbsp; As part of Our security procedures, You determine
                Your sign-up details or any piece of information You input on
                the App. By signing up for a ShopTopUp Profile, You agree to
                treat Your sign-in details as confidential and not to disclose
                those details to any third party. TradeDepot does not have
                access to Your sign-in details, if You forget Your sign-in
                details or know or suspect that a third party has gained access
                to Your sign-in-details, You must promptly change Your sign-in
                details, by resetting Your password or by contacting Us to place
                a restriction on Your ShopTopUp Profile.
                <br /> <br />
                1.8 &nbsp; You confirm that all information You provide to Us is
                true, correct and not misleading. You agree to provide any
                additional documents and/or information as may be required from
                time to time. In the event that the Information You provided to
                Us or a Verified Representative change, You hereby agree to
                notify Us of such changes immediately.
                <br /> <br />
                1.9 &nbsp; You consent to Us collecting and using technical
                information about the App and related software, hardware and
                peripherals to improve Our products and to provide any other
                services to You. If You use the Services, You consent to Us and
                Our affiliates&#39; and licensees&#39; to the processing, the
                transmission, collection, retention, maintenance, and use of
                Your personal data to improve Our Services and/or Your
                experience while using the App.
                <br /> <br />
                1.10 &nbsp; By accessing this App, You hereby authorize Us to
                provide the Services herein contemplated using the App.
              </span>
            </div>
            <div className="contract-section" id="kyc">
              <h5 className="section-title">
                2. ShopTopUp KYC And Profile Activation
              </h5>
              <span className="contract-text">
                Your ShopTopUp Profile will be active as soon as We are able to
                verify Your Information, and where necessary pay a visit to the
                address provided by You while signing up on the App.
              </span>
            </div>
            <div className="contract-section" id="wallet">
              <h5 className="section-title">3. ShopTopUp Wallet</h5>
              <span className="contract-text">
                3.1 &nbsp; YYour ShopTopUp Profile is accompanied by a unique
                digital ShopTopUp wallet (the{' '}
                <strong>"ShopTopUp Wallet"</strong>). The unique account number
                for Your ShopTopUp Wallet is displayed on the
                <strong>"Add Money"</strong> tab on the App. You must add a
                preferred bank account on the App.
                <br /> <br />
                3.2 &nbsp; You would be able to fund Your ShopTopUp Wallet by:
                (i) Funding Your ShopTopUp Wallet through electronic transfer;
                (ii) Funding Your ShopTopUp Wallet using the Unstructured
                Supplementary Service Data (USSD) code displayed on the App; and
                (iii)visiting the nearest mobile money agent or bank to deposit
                money into Your ShopTopUp Wallet through your unique account
                number .
                <br /> <br />
                3.3 &nbsp; Please note that while accessing Our services,
                standard/approved banking charges may apply. It is Your
                responsibility to pay all fees, or charges as may be updated
                from time to time.
                <br /> <br />
                3.4 &nbsp; If there is a problem charging Your ShopTopUp Wallet,
                We may charge any other valid payment method associated with
                Your ShopTopUp Profile.
                <br /> <br />
                3.1.5 &nbsp; Please note that We may share Your Information with
                third parties with to the extent required to provide the
                services and for the efficient performance of the App.
              </span>
            </div>
            <div className="contract-section" id="wallet-restrictions">
              <h5 className="section-title">
                4. ShopTopUp Wallet Restrictions
              </h5>
              <span className="contract-text">
                4.1 &nbsp; The ShopTopUp Wallet is provided to you for the sole
                purpose of the Services. You shall not attempt to make transfers
                out of Your ShopTopUp Wallet.
                <br /> <br />
                4.2 &nbsp; In the event there is a reasonable cause, TradeDepot
                shall have the right in its sole and absolute discretion to
                place restrictions on Your ShopTopUp Wallet and limit or prevent
                you from using the funds in Your ShopTopUp Wallet. Such
                restriction may extend to the number of times in 24
                (Twenty-Four) hours You will be able to use the funds in Your
                ShopTopUp Wallet to pay for Products.
                <br /> <br />
                4.3 &nbsp; You are expressly forbidden from any Service;
                <br />
                <ol type="i">
                  <li>
                    Using the funds in Your ShopTopUp Wallet to pay for products
                    other than the Products contemplated in this Agreement.
                  </li>
                  <li>
                    Using any TradeDepot or third-party content made available
                    through Your ShopTopUp Wallet for activities not
                    contemplated in this Terms of Use and the Related
                    Agreements. You agree not to use, copy, display, distribute,
                    modify, broadcast, translate, reproduce, reverse engineer,
                    reformat, incorporate into advertisements and other works,
                    sell, trade in, promote, create derivative works, or in any
                    way exploit or allow others to exploit any part of the
                    ShopTopUp Wallet.
                  </li>
                  <li>
                    Using the ShopTopUp Wallet for any fraudulent, malicious or
                    unlawful purposes or in any manner inconsistent with this
                    Terms of Use.
                  </li>
                </ol>
                4.4 &nbsp; You hereby agree to cooperate with TradeDepot and
                relevant agencies in any investigation conducted by TradeDepot
                or a governmental or regulatory body in connection with Your
                ShopTopUp Wallet.
              </span>
            </div>
            <div className="contract-section" id="payments">
              <h5 className="section-title">
                5. ShopTopUp Purchases And Payments
              </h5>
              <span className="contract-text">
                5.1 &nbsp; There is a minimum order requirement (excluding
                delivery charges) for every order placed (
                <strong>"the Minimum Order Requirement"</strong>). The Minimum
                Order Requirement is currently N30,000 (Thirty Thousand Naira)
                (excluding delivery charges), but we may change this from time
                to time, including temporary changes during certain periods. Any
                changes to the Minimum Order Requirement will be set out on the
                App. form.
                <br /> <br />
                5.2 &nbsp; Products featured on the App are subject to
                availability and prevailing market conditions. In the event of
                non-availability of any Products you order, You may browse the
                App for a list of Pay Partners near You or You may contact a
                Verified Representative on 08005550555 to assist You with
                locating a Pay Partner closest to You, from whom You may
                purchase Products from. We have the right to discontinue the
                sale of any Product featured on the App at any time, and our
                only liability in such cases will be limited to a refund of any
                money paid to Us for Products which We may no longer be able to
                deliver to You.
                <br /> <br />
                5.3 &nbsp; To purchase Products on the App, You would be
                required to select the Products You wish to purchase, the
                quantity of the Products and then add the selected Products to
                Your cart and click “Proceed to checkout” (
                <strong>"Offer"</strong>). You will be guided through the
                &quot;checkout&quot; process by a series of instructions. If You
                want to correct any errors you have made, you can use the
                functionality on the App to go back and remove Products from
                Your order or empty Your cart and start the Offer process all
                over again. Alternatively, You may contact a Verified
                Representative on 08005550555 to make an Offer by providing the
                Verified Representative with information about the Products and
                the quantity of the Products You wish to purchase (
                <strong>"Telesales Offer"</strong>).
                <br /> <br />
                5.4 &nbsp; Upon making an Offer, You may opt to pay for the
                Products with funds in Your ShopTopUp Wallet or to pay cash on
                delivery of the Products to You.
                <br /> <br />
                5.5 &nbsp; Your Offer or Telesales Offer is deemed accepted when
                You opt to pay for the Products on delivery of the Products to
                You or when You opt to pay for the Products through Your
                ShopTopUp Wallet and a complete payment is fully authorized by
                the payment gateway (<strong>"Acceptance"</strong>).
                <br /> <br />
                5.6 &nbsp; By opting to pay for the Products through Your
                ShopTopUp Wallet, You hereby authorize that automatic debit
                deductions be made in favor of TradeDepot from Your ShopTopUp
                Wallet for the price of the Products, applicable taxes, standard
                approved bank charges, and any fees incurred in relation to
                shipping of the Products to You.
                <br /> <br />
                5.7 &nbsp; We reserve the right to adjust prices, Products and
                special offers at our discretion. Deals, gift coupons and
                vouchers and other related promotions ("Promotions") are
                exclusive and cannot be combined and may not be exchanged
                against for cash. TradeDepot reserves the right to discontinue
                Promotions at any time without any prior notice.
                <br /> <br />
                5.8 &nbsp; The following shall apply to a TradeDepot Retailer
                who purchases Products from a Pay Partner:
                <br />
                <ol type="i">
                  <li>
                    You shall identify yourself as a TradeDepot Retailer to
                    enable a Pay Partner verify Your identity.
                  </li>
                  <li>
                    After a Pay Partner has verified Your Identity You may
                    proceed to shop for Products.
                  </li>
                  <li>
                    As soon as You are done selecting Products You wish to buy,
                    You shall present the Products to a Pay Partner.
                  </li>
                  <li>
                    A Pay Partner would calculate the cost of the Products You
                    selected and input the total amount of the Products in the
                    “amount” field on the Pay Partner’s app.
                  </li>
                  <li>
                    You are required to confirm the accuracy of the total amount
                    charged by a Pay Partner and thereafter allow a Pay Partner
                    to scan Your QR Code on his/her App.
                  </li>
                  <li>
                    Thereafter, You shall input Your unique ShopTopUp PIN in the
                    relevant field on the Pay Partner’s app to authorize payment
                    for the Products.
                  </li>
                  <li>
                    The total amount charged by a Pay Partner would be debited
                    from Your ShopTopUp Wallet.
                  </li>
                  <li>
                    You hereby agree and give Your consent to the sharing of
                    Your Information with third parties to the extent required
                    to provide the Services and for the efficient performance of
                    the App.
                  </li>
                  <li>
                    Please also note that standard/approved banking charges
                    shall apply to debit deductions made from Your ShopTopUp
                    Wallet.
                  </li>
                  <li>
                    TradeDepot bears no liability for the price/ quality of
                    Products purchased from Pay Partners.{' '}
                  </li>
                </ol>
                5.9 &nbsp; By authorizing payment for Products you bought from a
                Pay Partner You hereby authorize that automatic debit deductions
                be made in favor of TradeDepot from Your ShopTopUp Wallet, for
                the price of the Products, applicable taxes, standard approved
                bank charges, and any fees incurred in relation to shipping of
                the Products to You.
              </span>
            </div>
            <div className="contract-section" id="risk">
              <h5 className="section-title">
                6. Risk, Delivery Terms and Other Fees
              </h5>
              <span className="contract-text">
                6.1 &nbsp; Risk of loss and damage of Products passes to you at
                the time You pick up the Products or at the time the Products
                are delivered to you or are left with a person or in a place
                nominated by You (<strong>"Delivery"</strong>). We shall not be
                responsible for any loss or damage caused to the Products after
                Delivery.
                <br /> <br />
                6.2 &nbsp; All Deliveries are subject to delivery charges as
                communicated to You by Us.
                <br /> <br />
                6.3 &nbsp; We use reasonable efforts to ensure that Your
                Products are delivered on the delivery date provided in Your
                delivery confirmation message. However, please note the delivery
                dates provided by Us are only Our best estimates and the actual
                Delivery of Your Products may be later than the date provided.
                TradeDepot gives no guarantee or undertaking as to dates of
                dispatch or Delivery and shall accept no liability for any loss
                resulting to You from late Delivery of the Products.
                <br /> <br />
                6.4 &nbsp; In the event that Products are returned undelivered
                or refused at the time of Delivery, You hereby agree that We
                reserve the right to charge the full delivery charges for the
                back and forth movements of the Products and any other
                applicable fee and transaction charges.
                <br /> <br />
                6.5 &nbsp; Please note that Products featured on the App are for
                illustration purposes only. Upon pickup or delivery of the
                Products, The Products may look different than is shown in the
                image. TradeDepot bears no liability for differences in shape,
                color, or size of the Products.
                <br /> <br />
                6.6 &nbsp; You hereby agree that We are not liable for the
                quality of the Products delivered to You such as, but not
                limited to, taste, texture, colour etc. While We are willing to
                escalate any Product related complaint to the manufacturer, We
                are not responsible for the resolution of such complaints.
                <br /> <br />
                6.7 &nbsp; For the purpose of clauses 6.2, and 6.4, You hereby
                authorize that automatic debit deductions be made in favor of
                TradeDepot from Your ShopTopUp Wallet.
                <br /> <br />
                6.8 &nbsp; You hereby agree and give Your consent to the sharing
                of Your Information with third parties to the extent required to
                provide the Services and for the efficient performance of the
                App.
              </span>
            </div>
            <div className="contract-section" id="cash-advances">
              <h5 className="section-title">7. CASH ADVANCES</h5>
              <span className="contract-text">
                If You are interested in obtaining cash advances from
                TradeDepot, please refer to the Cash Advance Terms &amp;
                Conditions in this Agreement.
              </span>
            </div>
            <div className="contract-section" id="obligations">
              <h5 className="section-title">
                8. Obligations of a TradeDepot Retailer
              </h5>
              <span className="contract-text">
                8.1 &nbsp; You shall be responsible for ensuring the proper
                performance of any electronic or other device used to connect to
                the Services. TradeDepot shall neither be responsible for any
                errors or failures caused by any malfunction of Your device, nor
                shall TradeDepot be responsible for any computer virus or
                related problems that may be associated with the use of the App
                and the device. You shall be responsible for charges due to any
                service provider providing You with internet connection, and
                TradeDepot shall not be responsible for losses or delays caused
                by lack of internet connection.
                <br /> <br />
                8.2 &nbsp; You shall follow all instructions, procedures and
                terms contained in this Terms of Use and any document provided
                by TradeDepot concerning the use of the App.
                <br /> <br />
                8.3 &nbsp; You shall immediately inform TradeDepot in the event
                that You have reason to believe that unauthorized use of the App
                has or may have occurred or could occur and a transaction may
                have been fraudulently initiated or compromised.
                <br /> <br />
                8.4 &nbsp; You shall at all times follow the security procedures
                notified to You by TradeDepot from time to time or such other
                procedures as may be applicable to the Services from time to
                time. In particular, You shall ensure that the Services are not
                used by anyone other than You.
                <br /> <br />
                8.5 &nbsp; You shall not at any time operate or use the App in
                any manner that may be prejudicial to TradeDepot.
              </span>
            </div>
            <div className="contract-section" id="restrictions">
              <h5 className="section-title">9. Acceptable Use Restrictions</h5>
              <span className="contract-text">
                9.1 &nbsp; You are granted a non-exclusive, non-transferable,
                personal, limited license to use the App. Your limited right to
                use the App is revocable at Our discretion.
                <br /> <br />
                9.1.1 &nbsp; You shall not use the App in any unlawful manner,
                for any unlawful purpose, or in any manner inconsistent with
                this Terms of Use, or act fraudulently or maliciously.
                <br /> <br />
                9.1.2 &nbsp; You shall not connive with TradeDepot’s employees,
                Pay Partners, other TradeDepot Retailers and third parties to
                use the App or the Services to defraud TradeDepot.
                <br /> <br />
                9.1.3 &nbsp; You shall not use the credit obtained from
                TradeDepot to make payments for products, other than the
                Products contemplated in this Agreement.
                <br /> <br />
                9.1.4 &nbsp; You shall not create multiple ShopTopUp Profiles or
                operate multiple ShopTopUp Profiles through proxies. You and
                Your business must be associated with one ShopTopUp Profile at
                all times.
                <br /> <br />
                9.1.5 &nbsp; You shall not use the App or the Services and the
                credit obtained from TradeDepot to make payments to Pay Partners
                or other third parties for any debts, borrowing or any other
                transactions other than the purchase of Products contemplated in
                this Agreement.
                <br /> <br />
                9.1.6 &nbsp; You shall not register as a Pay Partner for the
                purpose of defrauding TradeDepot or for the purpose of using the
                App/Services for any unlawful purpose, or in any manner
                inconsistent with this Terms of Use and the Related Agreements.
                <br /> <br />
                9.1.7 &nbsp; You shall not use the App/Services to commit
                identity theft; neither shall You connive with friends, families
                and other third parties to defraud TradeDepot.
                <br /> <br />
                9.1.8 &nbsp; You shall not willfully destroy Your electronic
                devices, deactivate Your email address, destroy Your SIM card,
                or carry out other similar activities with the purpose of
                defrauding TradeDepot.
                <br /> <br />
                9.1.9 &nbsp; You shall not use the App in a manner that imply or
                promote support or funding of, or membership in, a terrorist
                organization.
                <br /> <br />
                9.1.10 &nbsp; You shall not infringe Our intellectual property
                rights or those of any third party in relation to Your use of
                the App, including the submission of any material, to the extent
                that such use is not licensed by this Terms of Use or the
                Related Agreements.
                <br /> <br />
                9.1.11 &nbsp; You shall not use the App or the Services in a way
                that could damage, disable, overburden, impair or compromise Our
                systems or security or interfere with other users.
                <br /> <br />
                9.1.12 &nbsp; You shall not use the Services to transmit malware
                or host phishing pages. You may not perform activities that harm
                or disrupt the operation of the Services or any of Our other
                infrastructure, or of others, including Our third party
                providers. You may not use the Services for deceptive commercial
                practices or any other illegal or deceptive activities
                <br /> <br />
                9.1.13 &nbsp; You shall not extract any information or data from
                the Services or Our systems or attempt to decipher any
                transmissions to or from the servers running the Services.
                <br /> <br />
                9.1.14 &nbsp; You shall not use the App or the Services in a
                manner that contravenes or that facilitate or promote activities
                that contravene applicable laws or this Terms of Use.
                <br /> <br />
                9.1.15 &nbsp; We may, at any time and without notice, deactivate
                Your ShopTopUp Profile or Your access to the Services if You
                engage in activities that violate clauses 9.1 to 9.1.14,
                including activities outside of Your use of the Services.
                <br /> <br />
                9.2 &nbsp; Without prejudice to clauses 14.1 to 14.2.4 herein,
                You hereby indemnify TradeDepot (and its directors, officers,
                affiliates, agents, successors and assigns against all claims,
                liabilities damages, costs and expenses (including attorney fees
                and related charges) for any breach of the provisions specified
                in clauses 9.1 to 9.1.14 of this Terms of Use.
                <br /> <br />
                9.3 &nbsp; TradeDepot shall have the right, but not the
                obligation, to monitor or investigate Your use of the Services
                at any time for compliance with this Terms of Use and the
                Related Agreements. Our determination of whether a breach of
                this Terms of Use has occurred will be final and binding, and
                any action taken with respect to enforcing the provisions
                specified in this Terms of Use, including taking no action at
                all, will be at Our sole discretion.
                <br /> <br />
                9.4 &nbsp; TradeDepot may modify the terms in clauses 9.1 to
                9.4, at any time. By continuing to use the Services or access
                Your ShopTopUp Profile after the terms specified in clauses 9.1
                to 9.4, has been modified, You agree to be bound by the modified
                terms.
                <br /> <br />
                9.5 &nbsp; If You suspect that TradeDepot Retailers, Pay
                Partners, staff of TradeDepot or other third parties are in
                breach of clauses 9.1 to 9.1.14, please contact Us via the App,
                a toll-free line on 08005550555, or you can send us an e-mail
                via{' '}
                <a href="mailto:enquiries@tradedepot.co">
                  enquiries@tradedepot.co
                </a>
                .
              </span>
            </div>
            <div className="contract-section" id="rights">
              <h5 className="section-title">
                10. Intellectual Property Rights & Confidentiality
              </h5>
              <span className="contract-text">
                10.1 &nbsp; Notwithstanding any other provision in this Terms of
                Use, TradeDepot shall remain the exclusive owner of the
                copyrights, and any other intellectual property rights in the
                App and the technology anywhere in the world. Rights in the App
                are licensed (not sold or assigned) to You, and that You have no
                rights in, or to, the App or the technology other than the right
                to use the App in accordance with the provisions specified in
                this Terms of Use. You acknowledge that You have no right to
                have access to the App in source-code form. Intellectual
                property means all patents, rights to inventions, utility
                models, copyright and related rights, trademarks, service marks,
                trade, business and domain names, rights in trade dress or
                get-ups, rights in goodwill or to sue for passing off, unfair
                competition rights, rights in designs, rights in computer
                software, database right, topography rights, moral rights,
                rights in confidential information including know how and trade
                secrets and any other intellectual property rights in each case
                whether registered or unregistered and including all
                applications for and renewals or extensions of such rights and
                all similar or equivalent rights or forms of protection in any
                part of the world and intellectual property right means any one
                of the intellectual property rights as the context so requires.
                <br /> <br />
                10.2 &nbsp; You hereby agree not to reproduce, duplicate, copy,
                sell, resell or exploit any portion of the Services. You hereby
                undertake not disclose any information associated with Our
                business and not publicly known, including specific business
                information, technical processes and formulas, software,
                customer lists, prospective customer lists, names, addresses and
                other information regarding customers and prospective customers,
                product designs, sales, costs (including any relevant processing
                fees), price lists, and other unpublished financial information,
                business plans and marketing data, (“Confidential information”)
                to any third party without the prior written consent of
                TradeDepot. Confidential Information shall include, but shall
                not be limited to, any and all and any other confidential and
                proprietary information, whether or not marked as confidential
                or proprietary. Our Confidential Information includes all
                information that You receive relating to Us or to the Services
                that is not known to the general public including information
                related to Our security program and practices.
              </span>
            </div>
            <div className="contract-section" id="data">
              <h5 className="section-title">11. Processing Of Personal Data</h5>
              <span className="contract-text">
                11.1 &nbsp; Any personal data that comes into Your possession
                while performing Your obligations under this Terms of Use must
                be processed in accordance with the Nigeria Data Protection Act
                2023 or any amendments thereto.
                <br /> <br />
                11.2 &nbsp; You will ensure that it has all necessary and
                appropriate consents and notices in place to enable, where
                necessary, lawful transfer of personal data to Us for the
                duration and purposes of this Terms of Use.
                <br /> <br />
                11.3 &nbsp; By using this App, You consent to Us, sharing the
                personal and other data provided by You in creating Your
                ShopTopUp Profile with third party contractors for the purpose
                of enabling efficient performance of the Services, ensuring the
                Cash Advance You obtained from TradeDepot is repaid, and
                effecting any payments or notifications that We need to make to
                You.
                <br /> <br />
                11.4 You shall ensure that You have in place appropriate
                technical and organizational measures, to protect against
                unauthorized or unlawful processing of personal data and against
                accidental loss or destruction of, or damage to, personal data,
                appropriate to the harm that might result from the unauthorized
                or unlawful processing or accidental loss, destruction or damage
                and the nature of the data to be protected.
              </span>
            </div>
            <div className="contract-section" id="delete-profile">
              <h5 className="section-title">
                12. Closing, Restriction & Cancellation of Your ShopTopUp
                Profile
              </h5>
              <span className="contract-text">
                12.1 &nbsp; TradeDepot may at its sole discretion restrict or
                close Your ShopTopUp Profile if:
                <br />
                <ol type="i">
                  <li>
                    You breach any of the Authorized User Restrictions set out
                    in this Terms of Use.
                  </li>
                  <li>
                    You use Your ShopTopUp Profile for unauthorized purposes or
                    where TradeDepot detects any abuse/misuse, breach of
                    content, fraud or attempted fraud relating to Your use of
                    the Services.
                  </li>
                  <li>
                    TradeDepot is required to comply with an order or
                    instruction of or a recommendation from the government,
                    court, regulator or other competent authority.
                  </li>
                  <li>
                    TradeDepot reasonably suspects or believes that You are in
                    breach of this Terms of Use and the Related Agreements.
                  </li>
                  <li>
                    Closing or restriction is necessary as a consequence of
                    technical problems or for reasons of safety; to facilitate
                    update or upgrade the contents or functionality of the
                    Services from time to time; or where Your ShopTopUp
                    Profile/Wallet becomes inactive or dormant.
                  </li>
                  <li>
                    TradeDepot decides to suspend or cease the provision of the
                    Services for commercial reasons or for any other reason as
                    it may determine in its absolute discretion.
                  </li>
                  <li>
                    You breach any of the provisions specified in this Terms of
                    Use and the Related Agreements.
                  </li>
                </ol>
                12.2 &nbsp; Closing or restriction of Your ShopTopUp Profile
                shall however not affect any of Our accrued rights and Your
                liabilities to Us.
                <br /> <br />
                12.3 &nbsp; You may cancel Your ShopTopUp Profile and terminate
                the Terms of Use at any time by contacting Us via the App, a
                toll-free line on 08005550555, or you can send us an e-mail via{' '}
                <a href="mailto:enquiries@tradedepot.co">
                  enquiries@tradedepot.co
                </a>
                .
                <br /> <br />
                12.4 &nbsp; Upon cancellation of Your ShopTopUp Profile,
                TradeDepot will cease providing You with the Services and You
                shall no longer be able to access Your ShopTopUp Profile/Wallet.
              </span>
            </div>
            <div className="contract-section" id="limitation">
              <h5 className="section-title">13. Limitation of Liability</h5>
              <span className="contract-text">
                13.1 &nbsp; TradeDepot shall not be responsible for any loss
                suffered by You should Your ShopTopUp Profile be closed,
                restricted, or cancelled.
                <br /> <br />
                13.2 &nbsp; TradeDepot shall not be responsible for any loss
                suffered by You should the Services be interfered with or be
                unavailable by reason of the failure of any of Your device, lack
                of internet connection or any other circumstances whatsoever not
                within Our control including, without limitation, system error,
                interruption, delay or non- availability of the Services, loss
                of power, adverse weather or atmospheric conditions, and failure
                of any public or private telecommunications system.
                <br /> <br />
                13.3 &nbsp; You hereby agree that, to the extent permitted by
                applicable laws, TradeDepot shall not be liable for any direct,
                indirect, incidental, special, consequential or exemplary
                damages, including but not limited to, damages for loss of
                profits, goodwill, use, data or other intangible losses
                resulting from the use of or inability to use the Service.
                <br /> <br />
                13.4 &nbsp; TradeDepot shall have no liability to You for any
                loss of profit, loss of business, business interruption, or loss
                of business opportunity as a result of Your unauthorized use of
                the App.
                <br /> <br />
                13.5 &nbsp; TradeDepot shall not be liable for any losses or
                damage suffered by You as a result of or in connection with:
                <br />
                <ol type="i">
                  <li>
                    any effect or fault in the App or the Services resulting
                    from You having altered or modified the App;
                  </li>
                  <li>
                    any defect or fault in the App resulting from You having
                    used the App in breach of this Terms of Use and the Related
                    Agreements; and
                  </li>
                  <li>
                    Your breach of any of the provisions of this Terms of Use
                    and the Related Agreements.
                  </li>
                </ol>
                13.6 &nbsp; TradeDepot does not warrant that the Services will
                be uninterrupted, timely, and secure or error free. All
                warranties and obligations implied by law are hereby excluded to
                the fullest extent permitted by law.
                <br /> <br />
                13.7 &nbsp; If the Services are interfered with or unavailable,
                Our sole liability under this Terms of Use respect thereof shall
                be to re-establish the Services as soon as reasonably possible.
              </span>
            </div>
            <div className="contract-section" id="indemnity">
              <h5 className="section-title">15. Indemnity</h5>
              <span className="contract-text">
                14.1 &nbsp; You hereby agree to indemnify and hold harmless
                TradeDepot (and its directors, officers, affiliates, agents,
                successors and assigns against all claims, liabilities damages,
                costs and expenses ( including attorney fees and related
                charges)) which TradeDepot suffers or incurs or sustains
                thereby, and You absolve TradeDepot from all liability for loss
                or damage which You may sustain from TradeDepot acting on Your
                instructions and/or instructions coming from Your ShopTopUp
                Profile, or sent electronically by; telephone, e-mail and
                letters in accordance with this Terms of Use and the Related
                Agreements.
                <br /> <br />
                14.2 &nbsp; The indemnity in clause 14.1 above, shall also
                include the following:
                <br /> <br />
                14.2.1 &nbsp; All demands, claims, actions, losses and damages
                of whatever nature which may be brought against TradeDepot or
                which We may suffer or incur arising from its acting or not
                acting on any request from Your Profile, or sent electronically
                by; telephone, e-mail and letters ,or arising from the
                malfunction or failure or unavailability of any hardware,
                software, or equipment, the loss or destruction of any data,
                power failures, corruption of storage media, riots, acts of
                vandalism, sabotage, terrorism, any other event beyond Our
                control.
                <br /> <br />
                14.2.2 &nbsp; Any loss or damage that may arise from Your use,
                misuse, abuse or possession of any third-party software,
                including without limitation, any operating system, browser
                software or any other software packages or programs.
                <br /> <br />
                14.2.3 &nbsp; Any unauthorized access to Your ShopTopUp Profile
                or any breach of security or any destruction or accessing of
                Your data or any destruction or theft of or damage to any of
                Your device.
                <br /> <br />
                14.2.4 &nbsp; Any loss or damage occasioned by Your failure to
                adhere to this Terms of Use and/or by supplying incorrect
                information.
                <br /> <br />
                14.2.5 &nbsp; Any loss or expenses arising from a fraud
                perpetrated by You or any expenses incurred by us in engaging
                the relevant regulatory authorities as may become necessary on
                account of your actions.
              </span>
            </div>
            <div className="contract-section" id="complaint">
              <h5 className="section-title">
                15. Our Complaint Handling Procedures
              </h5>
              <span className="contract-text">
                15.1 &nbsp; Meeting and exceeding our clients&#39; expectations
                is the most important part of our business. However, there may
                be occasions when something goes wrong and leaves you
                dissatisfied. If this happens, please let us know and we’ll do
                everything we can to resolve the problem with you. Our aim is to
                always resolve such matters as soon as we can and to your
                satisfaction.
                <br /> <br />
                <span className="key">15.2 Making a complaint </span>
                <br />
                <div className="ms-4 me-4">
                  If something has gone wrong and you would like to make a
                  formal complaint, please get in touch and let us know by
                  providing as much detail about the issue as you can. This can
                  be done by either:
                </div>
                <br />
                15.2.1 &nbsp; Sending us an email at:{' '}
                <a href="mailto:enquires@tradedepot.co">
                  enquires@tradedepot.co
                </a>
                <br /> <br />
                15.2.2 &nbsp; Calling our customer care number: 08005550555
                <br /> <br />
                15.2.3 &nbsp; Writing to us at: ¾ Adewunmi Industrial Estate,
                Kudirat Abiola Way Oregun Lagos
                <br /> <br />
                15.2.4 &nbsp; Contacting your Relationship Manager directly.
                <br /> <br />
                <strong>15.3 How we handle your complaint</strong>
                <div className="ms-4 me-4">
                  Once we have received Your complaint, we will begin our
                  investigation into what has happened. We will always aim to
                  resolve the issue as soon as possible. Once our investigation
                  into an issue is complete, will we send You our final response
                  to Your complaint.
                </div>
              </span>
            </div>
            <div className="contract-section" id="agreement">
              <h5 className="section-title">16. Entire Agreement</h5>
              <span className="contract-text">
                16.1.1 &nbsp; This Terms of Use and, where applicable the other
                Related Agreements constitute the entire agreement between You
                and Us and supersede and extinguish all previous agreements,
                promises, assurances, warranties, representations and
                understandings between Us, whether written or oral, relating to
                its subject matter.
                <br /> <br />
                16.1.2 &nbsp; You acknowledge that You do not rely on any
                statement, representation, assurance or warranty (whether made
                innocently or negligently) that is not referenced or set out in
                this Terms of Use and the Related Agreements.
              </span>
            </div>
            <div className="contract-section" id="majeure">
              <h5 className="section-title">17. Force Majeure</h5>
              <span className="contract-text">
                TradeDepot shall not be in breach of this Terms of Use nor
                liable for delay in performing, or failure to perform, any of
                its obligations under this Terms of Use if such delay or failure
                results from events, circumstances or causes beyond Our control.
                In such circumstances the time for performance shall be extended
                by a period equivalent to the period during which performance of
                the obligation has been delayed or failed to be performed.
              </span>
            </div>
            <div className="contract-section" id="beware-of-scam">
              <h5 className="section-title">18. Beware of Scam</h5>
              <span className="contract-text">
                18.1 &nbsp; Scammers are getting increasingly sophisticated in
                their attempts to have access to Your money, Your financial
                information and other personal information. It is Your
                obligation to keep Your Information and sign -in details safe.
                Do not share Your ShopTopUp Profile/Wallet details with anyone.
                <br /> <br />
                18.2 &nbsp; TradeDepot would never:
                <br />
                <ol type="i">
                  <li>
                    Send You suspicious texts, pop-up windows, links or
                    attachments in emails. If You receive any suspicious email/
                    text purporting to be from TradeDepot kindly disregard the
                    email and proceed to inform TradeDepot by clicking the
                    “Help” tab on the App.
                  </li>
                  <li>
                    Request Your sign-in details for the purpose of investing
                    the funds in Your ShopTopUp Wallet on Your behalf, and
                    receiving free Product/Product trials.
                  </li>
                  <li>
                    Have a tech support call You to inform You of viruses
                    detected on Your device, and requesting that you send a
                    screenshot of your QR code, click a link or provide Your
                    sign-in details or payment PIN for the purpose of providing
                    antivirus software to You.
                  </li>
                  <li>
                    Send an email, SMS, or call You or leave a voice message
                    requesting You repay the Cash Advance you obtained from
                    TradeDepot into an account provided by the caller or in the
                    SMS, threatening that, unless funds are wired immediately,
                    legal action will be taken or you&#39;ll be arrested. Kindly
                    click here to find out how a Cash Advance obtained from
                    TradeDepot can be re-paid.
                  </li>
                  <li>
                    Send an email, SMS, or call You or leave a voice message
                    informing you about a refund and requesting that You to
                    provide Your sign-in details or payment PIN to claim such
                    refund
                  </li>
                  <li>
                    Send an email, SMS, or call You or leave a voice message
                    informing You that TradeDepot is issuing payment cards and
                    requesting personal and financial information, or asking
                    that you click on link before being issued a payment card.
                  </li>
                  <li>
                    Send an email, SMS, or call You or leave a voice message
                    requesting that you make donations to a church, mosque,
                    charity cause or any non-governmental organization.
                  </li>
                  <li>
                    Send an email, SMS, or call You or leave a voice message
                    offering You a free gift card and requesting that You
                    provide Your sign-in details, payment PIN or click a link to
                    receive the free gift card.
                  </li>
                  <li>
                    Request that you provide Your sign -in details, payment PIN
                    to dispatch riders or any third party.{' '}
                  </li>
                  <li>
                    Ask any third party to request for a credit on Your behalf.
                  </li>
                  <br />
                  18.3 &nbsp; TradeDepot shall bear no liability for any loss of
                  business, loss of business opportunity, loss of revenue, loss
                  of profits, loss of anticipated savings, loss of goodwill,
                  business interruption, wasted expenditure or for loss of any
                  other economic advantage however it may arise, or for loss of
                  data or data corruption, or for any indirect, punitive,
                  special, incidental or consequential loss, arising as a result
                  of the events contemplated in clauses 18.1 -18.2(i)-(x).
                  <br /> <br />
                  18.4 &nbsp; In the event that You suspect that You may have
                  fallen victims to scammers, You are obliged to immediately
                  contact TradeDepot to place a restriction on Your ShopTopUp
                  Wallet. You shall be responsible for such charges and for any
                  transactions that occurred on Your ShopTopUp Profile/Wallet as
                  a result of the information or access You granted to scammers.
                  You hereby agree to indemnify and hold TradeDepot and its
                  affiliates harmless for any losses, liabilities, damages,
                  costs and charges arising out of or in relation to any
                  transactions that occurred on Your ShopTopUp Profile/Wallet
                  prior to placing a restriction on Your ShopTopUp Wallet.
                  <br /> <br />
                  18.5 &nbsp; TradeDepot takes reasonable measures in securing
                  all communications. However, please note that the
                  confidentiality of communications via any public
                  telecommunications network is susceptible to unlawful
                  interception and hacking. TradeDepot shall not be liable for
                  any loss or damage whether direct or consequential arising out
                  of any such unlawful interception or access.
                </ol>
              </span>
            </div>
            <div className="contract-section" id="assignment">
              <h5 className="section-title">19. Assignment</h5>
              <span className="contract-text">
                You shall not assign, transfer or novate any of Your rights and
                obligations under this Terms of Use without the written consent
                of TradeDepot.
              </span>
            </div>
            <div className="contract-section" id="severance">
              <h5 className="section-title">20. Severance</h5>
              <span className="contract-text">
                If any provision of this Terms of Use and the Related Agreements
                is determined by any court of competent jurisdiction or
                arbitrator to be invalid, illegal, or unenforceable to any
                extent, that provision shall, if possible, be construed as
                though more narrowly drawn, if a narrower construction would
                avoid such invalidity, illegality, or unenforceability or, if
                that is not possible, such provision shall, to the extent of
                such invalidity, illegality, or unenforceability, be severed,
                and the remaining provisions of this Terms of Use and the
                Related Agreements shall remain in effect.
              </span>
            </div>
            <div className="contract-section" id="partnership">
              <h5 className="section-title">21. No Partnership or Agency</h5>
              <span className="contract-text">
                This Terms of Use and the Related Agreements shall not be
                construed as creating any partnership, joint venture, agency or
                similar relationship between you and TradeDepot other than the
                relationship with respect to carrying out the terms of this
                Terms of Use and the Related Agreements. All rights, duties,
                obligations, and liabilities of TradeDepot and you shall be
                separate, individual and several and not joint or joint and
                several. Except as set forth in this Terms of Use and the
                Related Agreements, you have no authority to act as agent for
                TradeDepot or bind TradeDepot in any way. For general enquiries
                regarding this Terms of Use, please feel free to contact us at{' '}
                <a href="mailto:enquires@tradedepot.co">
                  enquires@tradedepot.co
                </a>{' '}
                or <a href="mailto:hello@shoptopup.com">hello@shoptopup.com</a>
              </span>
            </div>
            <div className="title" id="cash-advance-terms">
              <h5>CASH ADVANCE TERMS AND CONDITIONS</h5>
            </div>
            <div className="introduction">
              <span>
                If you wish to obtain a Cash Advance from TradeDepot, bear in
                mind, the provisions of this Cash Advance Terms of Use are
                binding on You in addition to the Terms of Use. The purpose of
                this Agreement is to outline the terms upon which, We may grant
                you a Cash Advance for the purpose of purchasing a stock of
                Consumer Goods (the <strong>"Products"</strong>) for your
                business.{' '}
                <strong>
                  Our overarching objective of granting you Cash Advance is to
                  help you grow your business, help you serve your customers
                  better and help you succeed
                </strong>
                . We fully expect that you would reciprocate this gesture by
                paying all amounts due and outstanding on the Cash Advance
                granted to You as and when due. Please be aware that We can only
                grant You a Cash Advance through the App, located at
                www.shoptopup.com.
              </span>
              <br /> <br />
              <span>
                This Cash Advance Terms &amp; Conditions ( also referred to as
                the <strong>"Agreement"</strong>), (together with our{' '}
                <a href="https://shoptopup.com/privacy" target="blank">
                  Privacy Policy
                </a>{' '}
                and the Terms of Use, referred to as the{' '}
                <strong>"Related Agreements"</strong>) sets out the complete
                terms and conditions upon which We may grant you a Cash Advance.
                This Agreement must therefore be read in conjunction with the
                Related Agreements. Be aware that this Agreement and the Related
                Agreements take effect on their date of publication. This Cash
                Advance Terms &amp; Conditions and the Related Agreements may be
                amended or varied by TradeDepot from time to time and your
                continued use and access to the Cash Advance constitutes your
                agreement to be bound by the terms of any such amendment or
                variation.
              </span>
              <br /> <br />
              <span>
                TradeDepot will take all reasonable measures to notify you of
                any changes. By requesting for a Cash Advance, You irrevocably
                and unconditionally agree that this Cash Advance Terms and
                Condition is legally a binding contract between TradeDepot as
                the <strong>"Lender"</strong> and yourself as the{' '}
                <strong>"Borrower"</strong>. You authorize us to take all legal
                steps necessary and to share your Personal Data with other third
                parties, including credit bureaus and government agencies, with
                the objective of recovering all amounts due and unpaid on any
                Cash Advance granted to You. It is Your sole responsibility to
                procure the services of a lawyer or other professional where You
                do not understand any of the terms of this Agreement for
                purposing of explaining or advising You as necessary. By
                accepting a Cash Advance, you agree that you (a) have read and
                fully understand the terms of this Agreement; (b) all the
                details above and the information supplied are accurate and
                true, and that You are aware that, we may report You to the
                Nigerian Police Force (<strong>NPF</strong>) or to the Economic
                and Financial Crimes Commission, (<strong>EFCC</strong>), where
                any of your actions or omissions in relation to this Agreement
                raises any suspicion of any criminal intent, action or omission;
                (c) You are aware that you are liable to pay interest and other
                transaction charges in connection with a Cash Advance received
                from Us; (d) Any questions You had in relation to the Agreement
                were answered to Your satisfaction.
              </span>
            </div>
            <br /> <br />
            <div className="contract-section" id="cash-eligibility">
              <h5 className="section-title">1. Eligibility for Cash Advance</h5>
              <span className="contract-text">
                1.1. &nbsp; To be eligible for Cash Advance, you must: (a) have
                an existing business; (b) sign up and register on the Shop Top
                Up App or be an existing shop app user; (c) complete a full KYC
                (Know Your Customer) documentation and have their physical
                addresses verified (d) keep the account/wallet funded; (e) make
                a purchase of Goods worth a minimum of ₦ 30,000 (Thirty Thousand
                Naira) and above; (f) must have completed at least three
                purchases on the App (g) Apply for the Cash Advance; (h) agree
                to all terms and conditions, and charges. By creating applying
                for a Cash Advance, you hereby consent to and give us access to
                your bank accounts for the purpose of assessing your credit
                worthiness for a Cash Advance.
                <br /> <br />
                1.2. &nbsp; Cash Advance requests above ₦1,000,000 (One Million
                Naira) will in addition to the above requirements provide (a) a
                statement of account covering the last 6 (Six) months from the
                date of Your Application for a Cash Advance (b) Confirmation by
                a TradeDepot Supervisor, that the wallet is funded before an
                order is made. TradeDepot Customers that request for a Cash
                Advance of over ₦1,000,000 (One Million Naira) and are
                considered worthy of a PoS Terminal are herein referred to as a
                “Cash Advance Plus Customer”.
                <br /> <br />
                1.3. &nbsp; By accepting a Cash Advance from Us, You hereby
                agree that you have fulfilled the above eligibility requirements
                which relates to You. You agree that if we find out later that
                you do not meet up with the eligibility requirements, we reserve
                the rights to immediately terminate this Agreement and
                immediately request, using all legal means possible, for a
                refund to Us, of the Cash Advance granted to You, including’ on
                the Cash Advance (<strong>"Cash Advance Interest"</strong>).
                <br /> <br />
                1.4. &nbsp; You hereby authorize Us to act on every request for
                a Cash Advance which you submit electronically through the App
                and agree that any application for a Cash Advance or acceptance
                of an offer of a Cash Advance submitted or generated
                electronically through the App shall have the same force and
                effect as if that application or acceptance bears inked original
                signature(s).
                <br /> <br />
                1.5. &nbsp; You hereby represent and warrant that all the
                information and documents provided by You are true, correct, and
                complete. In the event that the information provided by you
                changes, you agree to immediately notify us of such changes. If
                we find out that any information you provide is incorrect and
                false, whether as a result of your negligence or fraudulent
                intent, we would terminate this Agreement and immediately
                request, using all legal means possible, for a refund to Us, of
                the Cash Advance granted to You, including any Cash Advance
                Interest.
                <br /> <br />
                1.6. &nbsp; We reserve the right to verify the authenticity and
                status of your national identity number (NIN), bank accounts and
                bank verification numbers with the relevant service providers
                which may include a mobile network operator, credit bureau,
                deposit banks, and other financial institutions. By applying for
                a Cash Advance, you hereby consent and authorize Us to conduct
                background checks on You and to procure a credit report or other
                information on/about you from a credit bureau or any of such
                service providers. You agree to pay to Us, any costs and
                expenses associated with such background, credit reports/ checks
                or investigations.
                <br /> <br />
                1.7. &nbsp; TradeDepot reserves the right to decline Your
                application for a Cash Advance or to revoke that
                request/application at any stage at TradeDepot’s sole and
                absolute discretion and without giving any reason or giving any
                notice thereto. TradeDepot reserves the right (in its sole and
                absolute discretion) to issue, decline to extend a Cash Advance
                and/or vary the terms of any Cash Advance depending on Our
                assessment of Your eligibility from time to time.
              </span>
            </div>
            <div className="contract-section" id="offer-acceptance">
              <h5 className="section-title">2. Acceptance of Offer</h5>
              <span className="contract-text">
                You hereby accept Our offer of Cash Advance granted to You
                through the App, subject to provisions of this Cash Advance
                Terms of Use and the Related Agreements. The monetary value of
                the Cash Advance granted to You may be indicated in your
                ShopTopUp Wallet. Some of the terms of the Cash Advance granted
                to You can be displayed through your dashboard on the App. These
                terms may include: (a) the principal amount of the Cash Advance;
                (b) the applicable Cash Advance Interest; and (c) the period
                within which you have to repay the Cash Advance and the Cash
                Advance Interest.
              </span>
            </div>
            <div className="contract-section" id="cash-purpose">
              <h5 className="section-title">3. Purpose of Cash Advance</h5>
              <span className="contract-text">
                You must only use the Cash Advance hereby granted for purchasing
                Products from the App or from a registered Pay Partner. You
                cannot use the Cash Advance for any other purpose or for any
                personal, family or household purposes.
              </span>
            </div>
            <div className="contract-section" id="shop-wallet">
              <h5 className="section-title">4. Your ShopTopUp Wallet</h5>
              <span className="contract-text">
                The monetary value of the Cash Advance granted to You may be
                indicated in Your ShopTopUp Wallet through the unique account
                number associated with your online account on the App (
                <strong>"Unique Account Number"</strong>) and assigned to You at
                the point of registration on the App as a TradeDepot Retailer.
              </span>
            </div>
            <div className="contract-section" id="pos-terminal">
              <h5 className="section-title">5. Your PoS Terminal</h5>
              <span className="contract-text">
                5.1. &nbsp; If you are a Cash Advance Plus Customer, We may
                provide You with a Point of Sale Terminal (the{' '}
                <strong>"PoS Terminal"</strong>). which you have to pay for. You
                will pay part upfront and we will spread the remainder over the
                tenor of your advance.
                <br /> <br />
                5.2. &nbsp; In consideration of the Cash Advance granted to You,
                You hereby unconditionally and irrevocably appoint us as a cash
                collection agent, during the tenor of a Cash Advance or within
                any period of time when a Cash Advance to You is due and
                outstanding.
                <br /> <br />
                5.3. &nbsp; Pursuant to our appointment as your cash collection
                agent, You hereby authorize Us to (a) use our App to collect the
                electronic payments made by Your customers to You through the
                PoS Terminal ( the <strong>"Electronic Payments"</strong>); (b)
                use our App to deduct, from the Electronic Payments, on a daily
                basis, any amounts due and unpaid to Us on account of any Cash
                Advance provided to You, including an Cash Advance Interest (c)
                after deducting the amounts due to Us from the Electronic
                Payments, transfer the balance on the Electronic Payments to
                your bank account (d) deduct any amounts due and outstanding to
                Us from your bank account where the Electronic Payments are
                insufficient repay the Cash Advance or any amounts due to Us.
                <br /> <br />
                5.4. &nbsp; As further consideration for our provision of a Cash
                Advance to You, You hereby agree unconditionally and irrevocably
                to use only the PoS Terminal which we give You for accepting all
                payments from customers during the Tenor or within any period of
                time when a Cash Advance to You is due and outstanding. You
                agree not to use another PoS Terminal for processing the
                Electronic Payments during the tenor of a Cash Advance. You
                acknowledge that the PoS Terminal is simply a channel we have
                provided to make it easy for You to repay a Cash Advance, a Cash
                Advance Interest and any amounts due thereon. Therefore, You
                will continue to remain liable to repay the Cash Advance and any
                amounts due thereon in the event we are unable to recover any
                amounts due to us and it is your responsibility to ensure that
                any Cash Advance granted to You is repaid as and when due,
                whether through Your ShopTopUp Wallet, the PoS Terminal, (if You
                have been given one) or through other approved methods of
                payment approved in this Agreement or any related Agreement.
                <br /> <br />
                5.5. &nbsp; You agree to handle the PoS Terminal with utmost
                care and to comply with any instruction manuals and training
                provided to You in connection with Your Use and operation of the
                POS Terminal
                <br /> <br />
                5.6. &nbsp; We may initially provide You with free printing
                paper for the purpose of operating the PoS Terminal. It would be
                Your responsibility, to replace the said printing paper when it
                finishes and from time to time. We may also provide You with
                free internet data subscription for the PoS Terminal for the
                first 18 (Eighteen) months counting from the time a PoS Terminal
                is provided to You. After the said first 18 (Eighteen) months,
                it will be Your responsibility to replace and/or purchase the
                data subscription that is necessary to operate the PoS Terminal.
                <br /> <br />
                5.7. &nbsp; As a Cash Advance Plus Customer, You will only own
                the POS terminal after you have fully paid for the outstanding
                amount of the terminal at the end of your initial loan tenor.
                <br /> <br />
                5.8. &nbsp; You hereby acknowledge that the POS Terminal provide
                to You is new and is in good working condition.
                <br /> <br />
                5.9. &nbsp; You agree that TradeDepot shall have the sole right
                to repair or replace any POS Terminal.You agree that You shall
                not attempt to repair any such malfunctioning, damaged or
                destroyed PoS Terminal, whether by Yourself or through any other
                vendor.
                <br /> <br />
                5.10. &nbsp; You agree to notify TradeDepot within 1 (One) hour
                from the time any PoS Terminal malfunctions or is damaged or
                destroyed. You also agree to use the PoS Terminal in accordance
                with the manuals or instruction for the operation of the PoS
                Terminal.
                <br /> <br />
                5.11. &nbsp; You agree to pay the full costs for repair or
                replacement of any such damaged PoS Terminal. You must deposit
                the full costs of such repair as advised by Us into Your Shop
                Top Up Wallet during the period of any repair of the Pos
                Terminal, to enable us make the relevant daily deductions of the
                amounts due and payable to Us.
                <br /> <br />
                5.12. &nbsp; You agree that any breach of this clause with
                amount to an Event of Default under this Agreement and that, in
                that event, We shall have the right to immediately trigger our
                enforcement and repossession rights in relations to the Products
                pursuant to the terms of this Agreement.
              </span>
            </div>
            <div className="contract-section" id="other-fees">
              <h5 className="section-title">
                6. Tenor, Cash Advance Interest, Transaction and Other Fees
              </h5>
              <span className="contract-text">
                6.1. &nbsp; You would be required to pay Us a Cash Advance
                Interest equal to a charge of 2.5% of the principal amount of
                the Cash Advance granted to you, for an advancement of Cash
                Advance of a tenor of 2 (Two) weeks, and a charge of 5% of the
                principal amount of the Cash Advance granted to you for an
                advancement of Cash Advance for a tenor up to 4 (Four) weeks.
                The Cash Advance Interest payable may vary from time to time. We
                would make reasonable efforts to notify you of any such changes.
                <br /> <br />
                6.2. &nbsp; As a Cash Advance Plus Customer, you will be
                required to pay Us a Cash Advance Interest equal to 3.11%
                monthly and subject to a daily deduction of a portion of the
                amounts due to Us and a maximum repayment period/tenor of 6
                (Six) months or other tenor agreed with You for repayment. The
                Cash Advance Interest may be charged on a reducing balance
                basis.
                <br /> <br />
                6.3. &nbsp; As a Cash Advance Plus Customer, you will be
                required to purchase the POS at a cost of N65,000. You will need
                to pay N30,000 as a deposit and the remaining N35,000 will be
                added to your loan and repaid alongside the rest of your loan
                drawdowns. Please note the pricing of the POS devices is subject
                to market conditions and may be reviewed from time to time. As
                soon as your POS device is activated, the N35,000 POS loan is
                also activated and repayment starts. You will only fully own the
                POS after you have paid the outstanding 35k over the tenor of
                your initial loan.
                <br /> <br />
                6.4. &nbsp; As a Cash Advance Plus Customer, You will also be
                required to pay all costs associated with Our delivery of the
                PoS Terminal to You.
                <br /> <br />
                6.5. &nbsp; We and/or our payment partners may charge
                transaction fees, in connection with the transactions carried on
                within your ShopTopUp Wallet and from time to time amend or vary
                the transaction fees. If you are a Cash Plus Advance Customer,
                transactions on your PoS Terminal shall be subject to a minimum
                charge of 0.5% capped at N100 for every transaction on the PoS
                Terminal. We will use reasonable endeavors to notify you of any
                changes in relation to transaction fees.
                <br /> <br />
                6.6. &nbsp; You shall be responsible to pay the costs associated
                with registration of our security interests over the Products at
                the relevant movable asset registry.
                <br /> <br />
                6.7. &nbsp; You shall be liable to pay late fees of up to 48%
                (Forty-Eight Percent) of the total amount due and outstanding on
                the Cash Advance together with any Cash Advance Interest (“Late
                Fees”).
                <br /> <br />
                6.8. &nbsp; You shall be responsible for all legal costs and
                expenses incurred by Us in connection with any enforcement
                action taken by Us towards the collection and repayment of the
                Cash Advance and all amounts due and outstanding on any Cash
                Advance granted to You, including the costs of repossession of
                any Products and the legal expenses incurred towards filing a
                legal action against You for the recovery of any amounts due to
                Us.
              </span>
            </div>
            <div className="contract-section" id="security">
              <h5 className="section-title">7. Security</h5>
              <span className="contract-text">
                7.1. &nbsp; As security for our advance of the Cash Advance to
                You, You hereby as Chargor, grants to Us, as Chargee, for Our
                benefit and Our lenders or assigns, a first priority security
                interest in: (i) all the Products which constitutes the
                inventory/stock of Your business hereto now owned or hereafter
                acquired by You or Your business (collectively, the{' '}
                <strong>"Charged Interests"</strong>), (ii) all other property
                hereafter delivered to, or in the possession or in the custody
                of, Pledgee in substitution for or in addition to the Charged
                Interests, (iii) any other property of Chargor, now or hereafter
                delivered to, or in the possession or custody of Chargor, and
                (iv) all proceeds from the sale of the Products as collateral
                security for: (a) the prompt and complete payment when due
                (whether at the stated maturity, by acceleration or otherwise)
                of all amounts outstanding and unpaid relating to the Cash
                Advance, the Cash Advance Interest; and (b) the due and punctual
                payment and performance by you of your obligations and
                liabilities under, arising out of or in connection with this
                Agreement. You agree that the security hereby created gives
                TradeDepot the right to a repossession of the Products or any
                other products in your store/warehouse, including any
                store/warehouse controlled by You, at the time of any
                repossession by Us where you fail to comply with the terms of
                this Agreement or when any amount is due and unpaid by You in
                connection with a Cash Advance.
                <br /> <br />
                7.2. &nbsp; As additional security for the Cash Advance hereby
                granted, you hereby convey, assign, transfer the contractual and
                property rights to possess and or repossess without a Court
                Order, any and all Products in your custody at the time of
                default, (<strong>"Repossessed Property"</strong>) upon your
                failure to repay the Cash Advance or any amount due and
                outstanding on the Cash Advance or upon the occurrence of an
                Event of Default. You waive any rights to sue Us or any of our
                recovery agents for trespass, breach of privacy, breach of data
                privacy or breach of peace or any other legal rights you may
                have under any law, in so far as the actions taken by Us or our
                recovery agents are directed towards recovery of all amounts due
                and outstanding on the Cash Advance hereby granted to you. You
                hereby waive the rights to any notice or judicial or court
                hearing relating to our exercise of the rights to repossession
                herein granted. You agree that we may sell, lease or otherwise
                dispose of the Repossessed Products and that we may apply what
                we receive from the disposition of the Repossessed Products to
                Our expenses, legal expenses and the outstanding amounts on the
                Cash Advance granted to You. You agree that if the Repossessed
                Products are perishables or threatens to decline speedily in
                value, We may, without notice to you, dispose of any or all such
                Repossessed Products in a commercially reasonable manner and at
                your expense.
              </span>
            </div>
            <div className="contract-section" id="right-notice">
              <h5 className="section-title">
                8. Our Right and Notice to Assign to Third Parties
              </h5>
              <span className="contract-text">
                Trade Depot reserves the rights to assign the amount of any Cash
                Advance herein granted to You (including the amount of any Cash
                Advance Interest and any related security, rights, obligations
                or interests) whereof TradeDepot hereby notifies you of the
                transfer and assignment of all its rights, title to the Cash
                Advance hereby granted to You, (including the amount of any Cash
                Advance Interest and any related security, rights, obligations
                or interests), to TD Capital SPC acting for the account and on
                behalf of Segregated Portfolio, SP1 (the{' '}
                <strong>"Buyer"</strong>) (“Notice of Assignment”). By accepting
                Our offer of Cash Advance, You hereby acknowledge receipt of
                this Notice of Assignment and affirm that You have not received
                notice that any other person has an interest in the Cash Advance
                herein granted to You. Notwithstanding the foregoing, You will
                continue to be liable to pay all amounts due and outstanding,
                including any Cash Advance Interest in accordance with the
                provisions Agreement or as required by TradeDepot from time to
                time. You consent irrevocably and unconditionally to our
                provision to the Buyer of all information relating to the Cash
                Advance hereby granted to You including all your personal and
                business information provided at the time of registration on the
                App and at the time of You applied for a Cash Advance, as may
                from time to time be requested by the Buyer or its agents.
              </span>
            </div>
            <div className="contract-section" id="late-fees">
              <h5 className="section-title">
                9. Repayment of Cash Advance, Cash Advance Interest and Late
                Fees
              </h5>
              <span className="contract-text">
                9.1. &nbsp; All repayments of the Cash Advance and the Cash
                Advance Interest are to be made through the App or/and as
                provided for in clause 9.1(i)-(iv) or in the case of a Cash
                Advance Plus Customer, through the PoS Terminal. You may repay
                all amounts due and outstanding on the Cash Advance hereby
                granted to You by:
                <br />
                <ol type="i">
                  <li>
                    Paying all amounts due and outstanding on the Cash Advance
                    including the Cash Advance Interest and any Late Fees,
                    through the Payment Service Provider platform integrated
                    within App.
                  </li>
                  <li>
                    Funding your ShopTopUp Wallet with all amounts due and
                    outstanding on the Cash Advance including the Cash Advance
                    Interest and any Late Fees, through electronic transfer to
                    your Unique Account Number to enable direct debits to be
                    made in favor of TradeDepot towards your repayment of the
                    Cash Advance extended to you by TradeDepot.
                  </li>
                  <li>
                    Funding your ShopTopUp Wallet with all amounts due and
                    outstanding on the Cash Advance including the Cash Advance
                    Interest and any Late Fees, by using the Unstructured
                    Supplementary Service Data (USSD) code displayed on your
                    online profile on the App, to enable direct debits to be
                    made in favor of TradeDepot towards your repayment of the
                    Cash Advance extended to you by TradeDepot.
                  </li>
                  <li>
                    Visiting the nearest mobile money agent or bank to deposit
                    money into your Unique Account Number, to enable direct
                    debits to be made from your ShopTopUp Wallet in favor of
                    TradeDepot for the repayment of the Cash Advance extended to
                    you by TradeDepot.
                  </li>
                </ol>
                <br /> <br />
                9.2. &nbsp; You hereby agree and give your consent to the
                sharing of Your Information with third parties to the extent
                required to ensure repayment of the Cash Advance You obtained
                from Us.
                <br /> <br />
                9.3. &nbsp; By obtaining a Cash Advance from TradeDepot, you
                hereby authorize that automatic debit deductions be made in
                favor of TradeDepot from your ShopTopUp Wallet or the PoS
                Terminal, for the Cash Advance, Cash Advance Interest, Late
                Fees, applicable taxes, standard bank charges, and any fees
                incurred in relation to the grant of the Cash Advance.
                <br /> <br />
                9.4. &nbsp; All payments to be made by You under this Agreement
                shall be made in full without any set off or counter claim and
                save in so far as required by the law to the contrary, free and
                clear of and without any deduction or withholding whatsoever. If
                you are at any time required to make any deduction or
                withholding from any payment to TradeDepot, you shall
                immediately pay to TradeDepot such additional amounts as will
                result in TradeDepot receiving the full amount it would have
                received had no such deduction or withholding been required.
              </span>
            </div>
            <div className="contract-section" id="events">
              <h5 className="section-title">10. Events of Default</h5>
              <span className="contract-text">
                10.1. &nbsp; An event of default (Event of Default) occurs when:
                <br />
                <ol type="i">
                  <li>
                    You fail to pay any sum payable pursuant to the Cash Advance
                    granted to You in accordance with a scheduled repayment, for
                    any reason at all.
                  </li>
                  <li>
                    You fail to pay the Cash Advance Interest in accordance with
                    a scheduled repayment.
                  </li>

                  <li>
                    Any representation/information, warranty or assurance made
                    or given by you in connection with the application for Cash
                    Advance or any information or documentation supplied by you
                    is incorrect, inaccurate or misleading.
                  </li>
                  <li>
                    You do anything which may prejudice TradeDepot’s rights
                    under this Agreement.
                  </li>
                  <li>
                    You use or attempt to use the App for any unauthorized
                    purpose.
                  </li>
                  <li>
                    You are declared bankrupt, insolvent or unable to pay your
                    debts as they fall due under the laws of Nigeria.
                  </li>
                  <li>
                    As a Cash Advance Plus Customer, you fail for any reason at
                    all to use the PoS Terminal on any day during the Term or
                    during which any amount due and payable by you in connection
                    with a Cash Advance is outstanding and unsettled.
                  </li>
                  <li>
                    You fail to (a) fully comply with any trainings provided to
                    You regarding the use of the POS Terminal or Your Shop
                    Top-Up Wallet (b) fully comply with any instruction manuals
                    provided to You in connection with the operation of the POS
                    Terminal (c) immediately notify TradeDepot in the event of a
                    damage, destruction or malfunction of a PoS Terminal.
                  </li>
                  <li>
                    Where a POS Terminal is destroyed or damaged during the time
                    when it is within Your possession or use.
                  </li>
                  <li>
                    Where you fail to pay for the replacement or repair a POS
                    Terminal which malfunctions, is destroyed or damaged during
                    the time when it is within Your possession or use.
                  </li>
                  <li>
                    Where we are unable to make the relevant daily deduction on
                    the PoS Terminal or where Our ability to collect the
                    Electronic Payments or any amounts due to Us is hampered in
                    any way.
                  </li>
                </ol>
                <br /> <br />
                10.2. &nbsp; At any time after an Event of Default has occurred
                which is continuing, TradeDepot may, without prejudice to any
                other right or remedy granted to it under any law:
                <br />
                <ol type="i">
                  <li>terminate this Agreement immediately;</li>

                  <li>
                    declare that the Cash Advance (and all accrued Cash Advance
                    Interest and all other amounts outstanding under this
                    Agreement is immediately due and payable, whereupon they
                    shall become immediately due and payable; and
                  </li>
                  <li>
                    supply information concerning the Event of Default to credit
                    bureaus licensed to hold such Cash Advance default
                    information in Nigeria. A copy of any adverse information
                    concerning you sent to a credit bureau shall be made
                    available to you upon written request.
                  </li>
                </ol>
                <br /> <br />
                10.3. &nbsp; TradeDepot, whether acting for itself or for any of
                its assigns, reserves the right to assign its right, title and
                interest under the Agreement, including the right to sue for any
                unpaid amounts due and outstanding on the Cash Advance, to an
                external collections agency or to any third party who will take
                all reasonable steps to collect the amounts outstanding on any
                Cash Advance (together with any Cash Advance Interest, Late Fees
                and fees accrued), and in this regard, TradeDepot shall have the
                right to share with such collection agencies, any information
                about the Borrower which it deems relevant. Third parties
                engaged by Us towards the recollection of any outstanding
                amounts may share your information with other entities or
                contact your family members, friends, guarantors in a bid to
                recover all amounts outstanding. You hereby consent to the
                processing of your personal data in this manner and for this
                purpose.
                <br /> <br />
                10.4. &nbsp; TradeDepot also reserves the right to institute
                legal proceedings for the recovery of any amounts due and
                outstanding against You without any prior notice.
                <br /> <br />
                10.5. &nbsp; In the event that an Event of Default occurs, you
                grant to TradeDepot the right to notify You and any other person
                or third party related or affiliated to You, who, in
                TradeDepot’s opinion, may assist with the recovery of the
                outstanding amounts on the Cash Advance granted to you. You
                agree that this notification may be done by any means of
                communication which TradeDepot deems appropriate. By signing
                this Agreement, you hereby consent to the processing of your
                personal data in this manner and waive any legal standing or
                defenses you may have in that regard.
              </span>
            </div>
            <div className="contract-section" id="statements">
              <h5 className="section-title">11. Statements</h5>
              <span className="contract-text">
                11.1. &nbsp; A statement and activity report in respect of the
                Cash Advance granted to You will be made available on request.
                Requests shall be made via our e-mail address:{' '}
                <a href="mailto:enquiries@tradedepot.co">
                  enquiries@tradedepot.co
                </a>
                or via a contact link on the App.
                <br /> <br />
                11.2. &nbsp; The statement on the App shall provide details of
                the last 4 (four) transactions (or such other number of
                transactions as determined by TradeDepot) on your Cash Advance
                account initiated from your device. Your statement will show all
                amounts added or taken from your Cash Advance account. You must
                check your statement carefully and notify TradeDepot as soon as
                possible, and no later than 48 hours after receipt of your
                statement, if it includes any transaction or other entry which
                appears to you to be wrong or not made in accordance with your
                instructions.
                <br /> <br />
                11.3. &nbsp; TradeDepot reserves the right to rectify
                discrepancies, add and/or alter the entries in your statements,
                without prior notice to You.
                <br /> <br />
                11.4. &nbsp; Save for a manifest error, a statement issued to
                you in respect of your Cash Advance shall be conclusive evidence
                of the transactions carried out on your Cash Advance account for
                the period covered in the statement.
              </span>
            </div>
            <div className="contract-section" id="taxes">
              <h5 className="section-title">12. Taxes</h5>
              <span className="contract-text">
                12.1. &nbsp; All payments to be made by you in connection with
                this Agreement or any Cash Advance provided to You by TradeDepot
                are calculated without regard to any taxes payable by you in
                Nigeria. If any taxes are payable in connection with the
                repayments of the Cash Advance granted to you, you must ensure
                that you pay such taxes and that you pay to TradeDepot such
                additional amounts as will result in TradeDepot receiving the
                full amount it would have received had no such taxes been
                applicable to the payments.
                <br /> <br />
                12.2. &nbsp; You hereby consent and agree that TradeDepot may
                withhold amounts in your ShopTopUp Wallet if any tax authority
                in Nigeria requires TradeDepot to do so, or TradeDepot is
                otherwise required by law or pursuant to agreements with any tax
                authority to do so, or if TradeDepot needs to comply with
                internal policies or with any applicable order or sanction of a
                tax authority in Nigeria. You will, however, be notified if such
                deductions are made.
                <br /> <br />
                12.3. &nbsp; You acknowledge that to the full extent permitted
                by law, TradeDepot shall not be liable for any unauthorized
                drawing, debit, transfer, remittance, disclosure, any activity
                or any incident on your ShopTopUp Wallet or online profile by
                the fact of the knowledge and/or use or manipulation of your
                ShopTopUp profile, your ShopTopUp Wallet, personal
                identification number (PIN), password, ID or any means whether
                or not occasioned by your negligence or willful misconduct.
                <br /> <br />
                12.4. &nbsp; You hereby agree and authorize TradeDepot to obtain
                and procure your Personal Information from the Cash Advance
                bureaus and you further agree and consent to the disclosure and
                provision of such Personal Information by the Cash Advance
                bureaus.
                <br /> <br />
                12.5. &nbsp; TradeDepot reserves the right to request for
                further information from you pertaining to your application.
                Failure to provide such information within the time required by
                TradeDepot may result in TradeDepot declining to accept your
                application for a Cash Advance.
                <br /> <br />
                12.6. &nbsp; TradeDepot reserves the right to supply Cash
                Advance information and other personal information about you to
                Cash Advance bureaus. You agree that TradeDepot may also seek
                information on your credit worthiness from any such credit
                bureaus.
              </span>
            </div>
            <div className="contract-section" id="termination">
              <h5 className="section-title">13. Variation and Termination</h5>
              <span className="contract-text">
                13.1. &nbsp; TradeDepot may at any time, upon notice to you,
                terminate or vary its business relationship with you and close
                your ShopTopUp Wallet/Profile and in particular but without
                prejudice to the generality of the foregoing, TradeDepot may
                cancel a Cash Advance which it has granted and require the
                repayment of outstanding debts resulting from such Cash Advance
                within such time as TradeDepot may determine.
                <br /> <br />
                13.2. &nbsp; Without prejudice to Trade Depot’s rights under
                clause 13.1, TradeDepot may at its sole discretion suspend Cash
                Advance to you or close your ShopTopUp Wallet if:
                <br /> <br />
                13.2.1. &nbsp; you use the Cash Advance or your ShopTopUp Wallet
                for unauthorized purposes or where TradeDepot detects any
                abuse/misuse, breach of content, fraud or attempted fraud
                relating to your use of the App;
                <br /> <br />
                13.2.2. &nbsp; your agreement with a mobile network operator is
                terminated for whatever reason; or
                <br /> <br />
                13.2.3. &nbsp; TradeDepot is required or requested to comply
                with an order or instruction of or a recommendation from the
                government, court, regulator or other competent authority.
                <br /> <br />
                13.2.4. &nbsp; TradeDepot reasonably suspects or believes that
                you are in breach of this Agreement (including non-payment of
                any Cash Advance amount due from you where applicable) which you
                fail to remedy (if remediable) within 14 days after the service
                of notice by email, SMS or other electronic means requiring you
                to do so.
                <br /> <br />
                13.2.5. &nbsp; Such a suspension or variation is necessary as a
                consequence of technical problems or for reasons of safety; to
                facilitate update or upgrade the contents or functionality of
                the App from time to time; or where your ShopTopUp profile on
                the App or your ShopTopUp Wallet becomes inactive or dormant.
                <br /> <br />
                13.2.6. &nbsp; TradeDepot decides to suspend or cease the
                provision of the App or Cash Advance for commercial reasons, to
                protect its legitimate interests or for any other reason as it
                may determine in its absolute discretion.
                <br /> <br />
                13.3. &nbsp; Termination shall however not affect any accrued
                rights of TradeDepot.
                <br /> <br />
                13.4. &nbsp; If TradeDepot receives notice of your demise,
                TradeDepot shall not be obliged to allow any operation or
                withdrawal from your ShopTopUp Wallet by any person except upon
                production of administration letters from a competent authority
                or confirmed grant of letters of administration or confirmed
                grant of probate by your legal representatives duly appointed by
                a court of competent jurisdiction and upon our recovery of any
                amounts due and outstanding to Us.
              </span>
            </div>
            <div className="contract-section" id="exclusion">
              <h5 className="section-title">14. Exclusion of Liability</h5>
              <span className="contract-text">
                14.1. &nbsp; TradeDepot shall not be responsible for any loss
                suffered by you should the App be interfered with or be
                unavailable by reason of the failure of any of your device, or
                any other circumstances whatsoever not within Trade Depot’s
                control including, without limitation, any customary or
                non-customary force majeure event, error, interruption, delay or
                non- availability of the App, terrorist or any enemy action,
                loss of power, adverse weather or atmospheric conditions, and
                failure of any public or private telecommunications or financial
                services system.
                <br /> <br />
                14.2. &nbsp; You acknowledge that the App has not been developed
                to meet your individual requirements, and that it is therefore
                your responsibility to ensure that the facilities and functions
                of the App as described meet your requirements.
                <br /> <br />
                14.3. &nbsp; We have no liability to you for any loss of profit,
                loss of business, business interruption, or loss of business
                opportunity as a result of your unauthorized use of the App.
                <br /> <br />
                14.4. &nbsp; TradeDepot will not be liable for any losses or
                damage suffered by you as a result of or in connection with:
                <br /> <br />
                14.4.1. &nbsp; any defect or fault in the App resulting from you
                having altered or modified the App or any component of it;
                <br /> <br />
                14.4.2. &nbsp; any defect or fault in the App resulting from you
                having used the App or any component of it in breach of the
                terms of this Agreement;
                <br /> <br />
                14.4.3. &nbsp; unavailability of sufficient funds in your
                ShopTopUp Wallet;
                <br /> <br />
                14.4.4. &nbsp; failure, malfunction, interruption or
                unavailability of the App, your device, the applicable internet,
                telecommunications or financial service network or the
                applicable electronic payment system; the money in your
                ShopTopUp Wallet being subject to legal process or other
                encumbrance restricting payments or transfers thereof; your
                failure to give proper or complete instructions for payments or
                transfers relating to your ShopTopUp Wallet;
                <br /> <br />
                14.4.5. &nbsp; any unapproved, fraudulent or illegal use of the
                App, the Cash Advance or your device;
                <br /> <br />
                14.4.6. &nbsp; your failure to comply with the Agreement and the
                Related Agreements and any document or information provided by
                TradeDepot concerning the use of the App and the Cash Advance.
                <br /> <br />
                14.5. &nbsp; If for any reason other than a reason mentioned in
                clauses 14.1 to 14.4.6, the App or the availability of Cash
                Advance or other service on the App is interfered with or
                unavailable, TradeDepot’s sole liability under this Agreement in
                respect thereof shall be to re-establish the availability of the
                App, Cash Advance or any other service on the App.
                <br /> <br />
                14.6. &nbsp; Save as provided in clause 14.5, TradeDepot shall
                not be liable to you for any interference with or unavailability
                of the App or a Cash Advance, howsoever caused.
                <br /> <br />
                14.7. &nbsp; Under no circumstances shall TradeDepot be liable
                to You for any loss of profit or anticipated savings or for any
                indirect or consequential loss or damage of whatever kind,
                howsoever caused, arising out of or in connection with the
                availability or function of the App or a Cash Advance even where
                the possibility of such loss or damage is notified to
                TradeDepot.
                <br /> <br />
                14.8. &nbsp; All warranties and obligations implied by law are
                hereby excluded to the fullest extent permitted by law.
              </span>
            </div>
            <div className="contract-section" id="cash-indemnity">
              <h5 className="section-title">15. Indemnity</h5>
              <span className="contract-text">
                15.1. &nbsp; In consideration of TradeDepot complying with your
                instructions or requests for Cash Advance, You undertake to
                indemnify TradeDepot and it assigns, agents, employees and hold
                them harmless against any loss, charge, damage, expense, fee or
                claim which TradeDepot suffers or incurs or sustains thereby and
                you absolve TradeDepot from all liability for loss or damage
                which you may sustain from TradeDepot acting on your
                instructions or in accordance with this Agreement.
                <br /> <br />
                15.2. &nbsp; The indemnity in clause 15.1 shall also cover the
                following:
                <br /> <br />
                15.2.1. &nbsp; All demands, claims, actions, losses and damages
                of whatever nature which may be brought against TradeDepot or
                which it may suffer or incur arising from its acting or not
                acting on any request or arising from the malfunction or failure
                or unavailability of any hardware, software, or device, the loss
                or destruction of any data, power failures, corruption of
                storage media, natural phenomena, riots, acts of vandalism,
                sabotage, terrorism, any other event beyond TradeDepot’s
                control, interruption or distortion of communication links or
                arising from reliance on any person or any incorrect, illegible,
                incomplete or inaccurate information or data contained in any
                Request received by TradeDepot.
                <br /> <br />
                15.2.2. &nbsp; Any loss or damage that may arise from your use,
                misuse, abuse or possession of any third-party software,
                including without limitation, any operating system, browser
                software or any other software packages or programs.
                <br /> <br />
                15.2.3. &nbsp; Any unauthorized access to your online profile,
                ShopTopUp Wallet or any breach of security or any destruction or
                accessing of your data or any destruction or theft of or damage
                to any of your device.
                <br /> <br />
                15.2.4. &nbsp; Any loss or damage occasioned by the failure by
                you to adhere to this Agreement and/or by supplying of incorrect
                information or loss or damage occasioned by the failure or
                unavailability of third-party facilities or systems or the
                inability of a third party to process a transaction or any loss
                which may be incurred by TradeDepot as a consequence of any
                breach of this Agreement.
                <br /> <br />
                15.2.4. &nbsp; Any cost, liabilities or expenses incurred by Us
                to enforcing this Agreement or during a repossession as
                contemplated herein.
              </span>
            </div>
            <div className="contract-section" id="force-majeure">
              <h5 className="section-title">16. Force Majeure</h5>
              <span className="contract-text">
                We shall not be responsible for damages caused by delay or
                failure to perform undertakings under the terms of this
                Agreement or Related Agreements when the delay or failure is due
                to fires, strikes, floods, acts of God, pandemics, epidemics,
                lockdowns, curfews, lawful acts of public authorities, any
                circumstances beyond Our control or delays or defaults caused by
                common carriers, which cannot reasonably be foreseen or provided
                against.
              </span>
            </div>
            <div className="contract-section" id="communication">
              <h5 className="section-title">17. Communication Between Us</h5>
              <span className="contract-text">
                17.1. &nbsp; If the Borrower wishes to contact TradeDepot, or if
                there is an obligation in this Agreement requiring the Borrower
                to give notice to TradeDepot, you may contact Us via the App, a
                toll-free line on 08005550555, or you can send us an e-mail via{' '}
                <a href="mailto:enquiries@tradedepot.co">
                  enquiries@tradedepot.co
                </a>
                .
                <br /> <br />
                17.2. &nbsp; If we have to contact you or give you notice in
                writing, we will do so by posting such notice on our website; or
                by e-mail or SMS to the mobile phone number or e- mail address
                you provide to us in your request for the App.
              </span>
            </div>
            <div className="contract-section" id="general">
              <h5 className="section-title">18. General</h5>
              <span className="contract-text">
                18.1. &nbsp; <strong>Remedies Cumulative</strong> <br />
                No failure on the part of TradeDepot to exercise, or delay on
                its part in exercising, any right, power or remedy provided by
                this Agreement or by law shall operate as a waiver thereof, nor
                shall any single or partial exercise of any such right, power or
                remedy preclude any further or other exercise of that, or any
                other, right, power or remedy.
                <br /> <br />
                18.2. &nbsp; <strong>No Partnership</strong> <br />
                This Agreement shall not be construed as creating any
                partnership, joint venture, agency or similar relationship
                between you and TradeDepot other than the relationship with
                respect to carrying out the terms of this Agreement. All rights,
                duties, obligations, and liabilities of TradeDepot and you shall
                be separate, individual and several and not joint or joint and
                several. Except as set forth in this Agreement, you have no
                authority to act as agent for TradeDepot or bind TradeDepot in
                any way.
                <br /> <br />
                18.3. &nbsp; <strong>No Waiver</strong> <br />
                No failure by TradeDepot to exercise, and no delay in
                exercising, any right or remedy in respect of any provision of
                this Agreement shall operate as a waiver of such right or
                remedy.
                <br /> <br />
                18.4. &nbsp; <strong>Effect of invalidity</strong> <br />
                If any provision of this Agreement is determined by any court of
                competent jurisdiction or arbitrator to be invalid, illegal, or
                unenforceable to any extent, that provision shall, if possible,
                be construed as though more narrowly drawn, if a narrower
                construction would avoid such invalidity, illegality, or
                unenforceability or, if that is not possible, such provision
                shall, to the extent of such invalidity, illegality, or
                unenforceability, be severed, and the remaining provisions of
                this Agreement shall remain in effect.
                <br /> <br />
                18.5. &nbsp; <strong>Entire Agreement</strong> <br />
                These Agreement and Related Agreements constitute the entire
                agreement between you and us and supersede and extinguish all
                previous agreements, promises, assurances, warranties,
                representations and understandings between us, whether written
                or oral, relating to its subject matter. You acknowledge that in
                entering into this Agreement you do not rely on any statement,
                representation, assurance or warranty (whether made innocently
                or negligently) that is not set out in this Agreement and
                Related Agreements. The Agreement and the Related Agreements may
                be updated from time to time by TradeDepot. We would make
                reasonable efforts to inform You of any such updates.
              </span>
            </div>
            <div className="contract-section" id="dispute-resolution">
              <h5 className="section-title">
                19. Governing Law and Dispute Resolution
              </h5>
              <span className="contract-text">
                This Agreement and Related Agreements is made under, governed by
                and to be construed in accordance with the laws of the Federal
                Republic of Nigeria.
              </span>
            </div>
            <div className="mb-4"></div>
          </div>
          <div className="col-md-3 list">
            <ul className="terms-item">
              <li>
                <a
                  href="#introduction"
                  onClick={() => setActive('introduction')}
                  className={activeId === 'introduction' ? 'active' : ''}
                >
                  Introduction
                </a>
              </li>
              <li>
                <a
                  href="#profile-terms"
                  onClick={() => setActive('profile-terms')}
                  className={activeId === 'profile-terms' ? 'active' : ''}
                >
                  ShopTopUp Profile Terms
                </a>
              </li>
              <li>
                <a
                  href="#kyc"
                  onClick={() => setActive('kyc')}
                  className={activeId === 'kyc' ? 'active' : ''}
                >
                  ShopTopUp KYC And Profile Activation
                </a>
              </li>
              <li>
                <a
                  href="#wallet"
                  onClick={() => setActive('wallet')}
                  className={activeId === 'wallet' ? 'active' : ''}
                >
                  ShopTopUp Wallet
                </a>
              </li>
              <li>
                <a
                  href="#wallet-restrictions"
                  onClick={() => setActive('allet-restrictions')}
                  className={activeId === 'allet-restrictions' ? 'active' : ''}
                >
                  ShopTopUp Wallet Restrictions
                </a>
              </li>
              <li>
                <a
                  href="#payments"
                  onClick={() => setActive('payments')}
                  className={activeId === 'payments' ? 'active' : ''}
                >
                  ShopTopUp Purchases And Payments
                </a>
              </li>
              <li>
                <a
                  href="#risk"
                  onClick={() => setActive('risk')}
                  className={activeId === 'risk' ? 'active' : ''}
                >
                  Risk, Delivery Terms and Other Fees
                </a>
              </li>
              <li>
                <a
                  href="#cash-advances"
                  onClick={() => setActive('cash-advances')}
                  className={activeId === 'cash-advances' ? 'active' : ''}
                >
                  Cash Advances
                </a>
              </li>
              <li>
                <a
                  href="#obligations"
                  onClick={() => setActive('obligations')}
                  className={activeId === 'obligations' ? 'active' : ''}
                >
                  Obligations of a TradeDepot Retailer
                </a>
              </li>
              <li>
                <a
                  href="#restrictions"
                  onClick={() => setActive('restrictions')}
                  className={activeId === 'restrictions' ? 'active' : ''}
                >
                  Acceptable Use Restrictions
                </a>
              </li>
              <li>
                <a
                  href="#rights"
                  onClick={() => setActive('rights')}
                  className={activeId === 'rights' ? 'active' : ''}
                >
                  Intellectual Property Rights & Confidentiality
                </a>
              </li>
              <li>
                <a
                  href="#data"
                  onClick={() => setActive('data')}
                  className={activeId === 'data' ? 'active' : ''}
                >
                  Processing Of Personal Data
                </a>
              </li>
              <li>
                <a
                  href="#delete-profile"
                  onClick={() => setActive('delete-profile')}
                  className={activeId === 'delete-profile' ? 'active' : ''}
                >
                  Closing, Restriction & Cancellation of Your ShopTopUp Profile
                </a>
              </li>
              <li>
                <a
                  href="#limitation"
                  onClick={() => setActive('limitation')}
                  className={activeId === 'dlimitation' ? 'active' : ''}
                >
                  Limitation of Liability
                </a>
              </li>
              <li>
                <a
                  href="#indemnity"
                  onClick={() => setActive('indemnity')}
                  className={activeId === 'indemnity' ? 'active' : ''}
                >
                  Indemnity
                </a>
              </li>
              <li>
                <a
                  href="#complaint"
                  onClick={() => setActive('complaint')}
                  className={activeId === 'complaint' ? 'active' : ''}
                >
                  Our Complaint Handling Procedures
                </a>
              </li>
              <li>
                <a
                  href="#agreement"
                  onClick={() => setActive('agreement')}
                  className={activeId === 'agreement' ? 'active' : ''}
                >
                  Entire Agreement
                </a>
              </li>
              <li>
                <a
                  href="#majeure"
                  onClick={() => setActive('majeure')}
                  className={activeId === 'majeure' ? 'active' : ''}
                >
                  Force Majeure
                </a>
              </li>
              <li>
                <a
                  href="#beware-of-scam"
                  onClick={() => setActive('beware-of-scam')}
                  className={activeId === 'beware-of-scam' ? 'active' : ''}
                >
                  Beware of Scam
                </a>
              </li>
              <li>
                <a
                  href="#assignment"
                  onClick={() => setActive('assignment')}
                  className={activeId === 'assignment' ? 'active' : ''}
                >
                  Assignment
                </a>
              </li>
              <li>
                <a
                  href="#severance"
                  onClick={() => setActive('severance')}
                  className={activeId === 'severance' ? 'active' : ''}
                >
                  Severance
                </a>
              </li>
              <li>
                <a
                  href="#partnership"
                  onClick={() => setActive('partnership')}
                  className={activeId === 'partnership' ? 'active' : ''}
                >
                  No Partnership or Agency
                </a>
              </li>
              <li>
                <a
                  href="#cash-advance-terms"
                  onClick={() => setActive('cash-advance-terms')}
                  className={activeId === 'cash-advance-terms' ? 'active' : ''}
                >
                  Cash Advance Terms and Condition
                </a>
              </li>
              <li>
                <a
                  href="#cash-eligibility"
                  onClick={() => setActive('cash-eligibility')}
                  className={activeId === 'cash-eligibility' ? 'active' : ''}
                >
                  Eligibility for Cash Advance
                </a>
              </li>
              <li>
                <a
                  href="#offer-acceptance"
                  onClick={() => setActive('offer-acceptance')}
                  className={activeId === 'offer-acceptance' ? 'active' : ''}
                >
                  Acceptance of Offer
                </a>
              </li>
              <li>
                <a
                  href="#cash-purpose"
                  onClick={() => setActive('cash-purpose')}
                  className={activeId === 'cash-purpose' ? 'active' : ''}
                >
                  Purpose of Cash Advance
                </a>
              </li>
              <li>
                <a
                  href="#shop-wallet"
                  onClick={() => setActive('shop-wallet')}
                  className={activeId === 'shop-wallet' ? 'active' : ''}
                >
                  Your ShopTopUp Wallet
                </a>
              </li>
              <li>
                <a
                  href="#pos-terminal"
                  onClick={() => setActive('pos-terminal')}
                  className={activeId === 'pos-terminal' ? 'active' : ''}
                >
                  Your PoS Terminal
                </a>
              </li>
              <li>
                <a
                  href="#other-fees"
                  onClick={() => setActive('other-fees')}
                  className={activeId === 'other-fees' ? 'active' : ''}
                >
                  Tenor, Cash Advance Interest, Transaction and Other Fees
                </a>
              </li>
              <li>
                <a
                  href="#security"
                  onClick={() => setActive('security')}
                  className={activeId === 'security' ? 'active' : ''}
                >
                  Security
                </a>
              </li>
              <li>
                <a
                  href="#right-notice"
                  onClick={() => setActive('right-notice')}
                  className={activeId === 'right-notice' ? 'active' : ''}
                >
                  Our Right and Notice to Assign to Third Parties
                </a>
              </li>
              <li>
                <a
                  href="#late-fees"
                  onClick={() => setActive('late-fees')}
                  className={activeId === 'late-fees' ? 'active' : ''}
                >
                  Repayment of Cash Advance, Cash Advance Interest and Late Fees
                </a>
              </li>
              <li>
                <a
                  href="#events"
                  onClick={() => setActive('events')}
                  className={activeId === 'events' ? 'active' : ''}
                >
                  Events of Default
                </a>
              </li>
              <li>
                <a
                  href="#statements"
                  onClick={() => setActive('statements')}
                  className={activeId === 'statements' ? 'active' : ''}
                >
                  Statements
                </a>
              </li>
              <li>
                <a
                  href="#taxes"
                  onClick={() => setActive('taxes')}
                  className={activeId === 'taxes' ? 'active' : ''}
                >
                  Taxes
                </a>
              </li>
              <li>
                <a
                  href="#termination"
                  onClick={() => setActive('termination')}
                  className={activeId === 'termination' ? 'active' : ''}
                >
                  Variation and Termination
                </a>
              </li>
              <li>
                <a
                  href="#exclusion"
                  onClick={() => setActive('exclusion')}
                  className={activeId === 'exclusion' ? 'active' : ''}
                >
                  Exclusion of Liability
                </a>
              </li>
              <li>
                <a
                  href="#cash-indemnity"
                  onClick={() => setActive('cash-indemnity')}
                  className={activeId === 'cash-indemnity' ? 'active' : ''}
                >
                  Indemnity
                </a>
              </li>
              <li>
                <a
                  href="#force-majeure"
                  onClick={() => setActive('force-majeure')}
                  className={activeId === 'force-majeure' ? 'active' : ''}
                >
                  Force Majeure
                </a>
              </li>
              <li>
                <a
                  href="#communication"
                  onClick={() => setActive('communication')}
                  className={activeId === 'communication' ? 'active' : ''}
                >
                  Communication Between Us
                </a>
              </li>
              <li>
                <a
                  href="#general"
                  onClick={() => setActive('general')}
                  className={activeId === 'general' ? 'active' : ''}
                >
                  General
                </a>
              </li>
              <li>
                <a
                  href="#dispute-resolution"
                  onClick={() => setActive('dispute-resolution')}
                  className={activeId === 'dispute-resolution' ? 'active' : ''}
                >
                  Governing Law and Dispute Resolution
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
