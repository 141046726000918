import React, { useState } from 'react';
import './drive.scss';
import Logo from '../assets/Logo-drive.svg';
import ForwardIcon from '../assets/Navigate-forward.svg';
import User from '../assets/user.svg';
import Doc from '../assets/doc.svg';
import Training from '../assets/train.svg';
import Vehicle from '../assets/vehicle.svg';
import Money from '../assets/money.svg';
import MoneyWhite from '../assets/money-white.svg';
import VehicleWhite from '../assets/vehicle-white.svg';
import Services from '../assets/services.svg';
import Cancel from '../assets/cancel.svg';
import BusTwo from '../assets/imgs/bus-2.png';
import BusOne from '../assets/imgs/bus-1.png';
import BusThree from '../assets/imgs/bus-3.png';
import ArrowUps from '../assets/imgs/logo-placeholder-white.png';
import { CarType } from './car';

function Drive() {
  const [country, setCountry] = useState('');
  return (
    <div className="stu-drive">
      <div className="container-fluid stu-drive_top-section">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 col-12">
            <div class="stu-drive_top-section_navigation">
              <div className="stu-drive_top-section_navigation_logo-side">
                <img src={Logo} alt="ShopTopUp logo" />
                <span className="breaker"></span>
                <h4>ShopTopUp Drive</h4>
              </div>

              <a
                href="#registerform"
                className="stu-drive_top-section_navigation_button"
              >
                Get Started now <img src={ForwardIcon} alt="forward-icon" />
              </a>
            </div>
            <div className="stu-drive_top-section_body">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="stu-drive_top-section_body_left">
                    <h1>Drive. Earn. Own.</h1>
                    <h5>
                      We have a van with your name on it. Earn money weekly for
                      driving to own your van.
                    </h5>
                  </div>
                </div>
                <div className="col-md-6 col-12" id="registerform">
                  <div className="stu-drive_top-section_body_right">
                    <h4>Start Driving</h4>
                    <hr />
                    <div className="stu-drive_top-section_body_right-form">
                      <div className="row">
                        <div className="col-12">
                          <label for="city" name="city">
                            Select City
                          </label>
                          <select
                            name="email"
                            type="select"
                            className="form-control"
                            placeholder="name@example.com"
                            onChange={({ target: { value } }) =>
                              setCountry(value)
                            }
                          >
                            <option disabled selected>
                              Select Your City
                            </option>
                            <option value="Lagos">Lagos</option>
                            <option value="Ibadan">Ibadan</option>
                            <option value="Abuja">Abuja</option>
                            <option value="Accra">Accra</option>
                            <option value="Ilorin">Ilorin</option>
                            <option value="Kano">Kano</option>
                            <option value="Port Harcourt">Port Harcourt</option>
                            <option value="Calabar">Calabar</option>
                            <option value="Jos">Jos</option>
                          </select>
                        </div>
                        <div className="col-6"></div>
                      </div>
                      <button
                        type="submit"
                        className="stu-drive_top-section_body_right_form-button"
                        disabled={!country}
                        data-tf-popup="pNmmaFtq"
                        data-tf-iframe-props="title=STU-Drive"
                      >
                        Get Started <img src={ForwardIcon} alt="forward-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <div className="container-fluid stu-drive_section-two">
        <div className="container">
          <h4 className="stu-drive_section-two_title">
            How ShopTopUp Drive-to-Own works
          </h4>
          <div className="row">
            <div className="col-md-4 col-12 stu-drive_section-two_howto">
              <div className="stu-drive_section-two_howto_icon-wrapper">
                <img src={User} alt="user" className="img-fluid" />
              </div>
              <p className="stu-drive_section-two_howto_point-title">Signup</p>
              <p className="stu-drive_section-two_howto_point-text">
                Create an account and complete your registration to kick off
                your journey to becoming a vehicle owner.
              </p>
            </div>
            <div className="col-md-4 col-12 stu-drive_section-two_howto">
              <div className="stu-drive_section-two_howto_icon-wrapper">
                <img src={Doc} alt="user" className="img-fluid" />
              </div>
              <p className="stu-drive_section-two_howto_point-title">
                Get verified
              </p>
              <p className="stu-drive_section-two_howto_point-text">
                Create an account and complete your registration to kick off
                your journey to becoming a vehicle owner.
              </p>
            </div>
            <div className="col-md-4 col-12 stu-drive_section-two_howto">
              <div className="stu-drive_section-two_howto_icon-wrapper">
                <img src={Training} alt="user" className="img-fluid" />
              </div>
              <p className="stu-drive_section-two_howto_point-title">
                Get trained
              </p>
              <p className="stu-drive_section-two_howto_point-text">
                The next step is to take you through our driver onboarding
                programme so that you understand the good work you will help us
                accomplish.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-12 stu-drive_section-two_howto">
              <div className="stu-drive_section-two_howto_icon-wrapper">
                <img src={Vehicle} alt="user" className="img-fluid" />
              </div>
              <p className="stu-drive_section-two_howto_point-title">
                Get your vehicle
              </p>
              <p className="stu-drive_section-two_howto_point-text">
                Congratulations! Sign your contract, collect your vehicle and
                start driving.
              </p>
            </div>
            <div className="col-md-4 col-12 stu-drive_section-two_howto">
              <div className="stu-drive_section-two_howto_icon-wrapper">
                <img src={Money} alt="user" className="img-fluid" />
              </div>
              <p className="stu-drive_section-two_howto_point-title">
                Get paid weekly
              </p>
              <p className="stu-drive_section-two_howto_point-text">
                Earn money weekly for completing delivery trips to ShopTopUp
                customers. Your agreed weekly repayment will be deducted from
                your weekly salary.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container stu-drive_section-three">
        <h4 className="stu-drive_section-three_title">Our Vans</h4>
        <div className="stu-drive_section-three_cars">
          <CarType img={BusOne} carname="Volkswagen LT35" />
          <CarType img={BusTwo} carname="Volkswagen T4" />
          <CarType img={BusThree} carname="Fiat Ducato" />
        </div>
      </div>
      <div className="container-fluid stu-drive_section-four">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-12">
              <h2 className="stu-drive_section-four_title">
                Explore our Benefits
              </h2>
              <img
                src={ArrowUps}
                className="img-fluid stu-drive_section-four_arrowups"
                alt="arrow-ups"
              />
            </div>
            <div className="col-md-2" />
            <div className="col-md-7 col-12 stu-drive_section-four_benefits">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="stu-drive_section-four_benefits_icon-wrapper">
                    <img src={VehicleWhite} alt="user" className="img-fluid" />
                  </div>
                  <p className="stu-drive_section-four_benefits_title">
                    Own your Vehicle
                  </p>
                  <p className="stu-drive_section-four_benefits_text">
                    We have a van with your name on it. Earn money weekly for
                    driving to own your van.
                  </p>
                </div>
                <div className="col-md-6 col-12">
                  <div className="stu-drive_section-four_benefits_icon-wrapper">
                    <img src={Services} alt="user" className="img-fluid" />
                  </div>
                  <p className="stu-drive_section-four_benefits_title">
                    Free basic services
                  </p>
                  <p className="stu-drive_section-four_benefits_text">
                    Get free vehicle servicing, fuel, road side assistance and
                    insurance.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="stu-drive_section-four_benefits_icon-wrapper">
                    <img src={MoneyWhite} alt="user" className="img-fluid" />
                  </div>
                  <p className="stu-drive_section-four_benefits_title">
                    Earn a living
                  </p>
                  <p className="stu-drive_section-four_benefits_text">
                    We have a van with your name on it. Earn money weekly for
                    driving to own your van.
                  </p>
                </div>
                <div className="col-md-6 col-12">
                  <div className="stu-drive_section-four_benefits_icon-wrapper">
                    <img src={Cancel} alt="user" className="img-fluid" />
                  </div>
                  <p className="stu-drive_section-four_benefits_title">
                    No hidden charges
                  </p>
                  <p className="stu-drive_section-four_benefits_text">
                    You pay no extra fees to us when driving for us and after
                    you own your vehicle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid stu-drive_section-five">
        <h2 className="stu-drive_section-five_title">Own your vehicle</h2>
        <p className="stu-drive_section-five_text">
          We have a van with your name on it. Earn money weekly for driving to
          own your van.
        </p>
        <a href="#registerform" className="stu-drive_section-five_button">
          Get Started <img src={ForwardIcon} alt="forward-icon" />
        </a>
      </div>
      <div className="container stu-drive_footer">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="stu-drive_footer_left">
              <div className="stu-drive_footer_left-brand">
                <img src={Logo} alt="stu-logo" className="img-fluid" />
                <span className="breaker"></span>
                <h4>ShopTopUp Drive</h4>
              </div>
              <div className="stu-drive_footer_left_links">
                <a href="/">How it works</a>
                <a href="/">Terms</a>
                <a href="/">Contact Us</a>
              </div>
            </div>
          </div>
          <div className="col-4"></div>
          <div className="col-md-4 col-12">
            <p className="stu-drive_footer_right">
              For further questions and assistance, contact us.
              <br />
              0800 555 0000
              <br />
              3/4 Adewunmi Industrial Estate,
              <br />
              Kudirat Abiola Way, Oregun, Lagos
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drive;
