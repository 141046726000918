import React from 'react';
import Header from './components/header';
import './styles.scss';

const PrivacyPolicy = () => {
  const [activeId, setActive] = React.useState('introduction');
  return (
    <div className="terms">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-12">
            <div className="title">
              <h5>PRIVACY POLICY</h5>
            </div>
            <div className="contract-section" id="introduction">
              <h5 className="section-title agreed">1. Introduction</h5>
              <span className="contract-text">
                1.1 TradeDepot{' '}
                <strong>(“TradeDepot” or “Us” or “We” or “Our”)</strong> is a
                retail technology company based in Lagos Nigeria with Our
                registered address at 3/4 Adewunmi Industrial Estate, Kudirat
                Abiola Way, Oregun, Lagos. We are dedicated to protecting the
                confidentiality and privacy of personally identifiable
                information entrusted to Us <strong>(“Personal Data”)</strong>.
                As part of that fundamental obligation, We are committed to the
                protecting the use of the Personal Data which We collect on
                &nbsp;
                <a href="https://shoptopup.com">https://shoptopup.com</a> (the
                &nbsp;
                <strong>“Website”</strong>). This Website helps you grow your
                retail business quickly by giving you instant loans to stock up
                your shop. The services We provide include the provisions of
                loans and all other services provided to you on your behalf
                through the Website from time to time{' '}
                <strong>(“Services”)</strong> This privacy policy{' '}
                <strong>(“Privacy Statement”)</strong> sets out the basis upon
                which We will process, store, and disclose any Personal Data
                that is provided voluntarily by users of the Website{' '}
                <strong>(“Data Subject” or “you” or “your”)</strong> in
                connection with the Services that We offer
                <br /> <br />
                1.2 We note that the Nigeria Data Privacy Regulations (NDPR)
                distinguishes between entities that are “controllers” of
                Personal Data and entities that “process” Personal Data. We note
                that We are a “Data Controller” where We determine the purposes
                for and the manner in which Personal Data is processed or is to
                be processed and We are a “Processor” for those Services where
                We only use Personal Data which are requested by other Data
                Controllers.
                <br /> <br />
                1.3 Where We make decisions on how Personal Data is used in
                connection with Our Services (where We are acting as a
                controller), We are responsible for the obligations of a
                “Controller” under the NDPR in connection with the processing of
                Personal Data – for example, We use this Privacy Statement and
                other notices to provide you with information about Our use of
                personal information, as required by the NDPR. Where We only use
                Personal Data requested by other Data Controllers (where We are
                acting as a processor), those other Data Controllers are
                similarly responsible for the obligations of a “Controller”
                under applicable laws in connection with the processing of
                personal information, and, if you are using Our Services through
                those other Controllers, you should contact them if you have
                questions or concerns about the processing of your personal
                information or compliance with the NDPR and other applicable
                laws.
                <br /> <br />
                1.4 This Privacy Statement may change from time to time. Unless
                otherwise stated, any updates to this Privacy Statement become
                effective when We post the updates on the Website. Your
                continued use of the Website for Our Services following an
                update to the Privacy Statement means that you accept the
                updated Privacy Statement.
              </span>
            </div>
            <div className="contract-section" id="privacy-rights">
              <h5 className="section-title">2. Your Privacy Rights</h5>
              <span className="contract-text">
                2.1 This Privacy Statement describes your privacy rights
                regarding Our collection, use, storage, sharing and protection
                of your Personal Data. It applies to this Website and all other
                related applications, services, tools and physical contact with
                Us regardless of how you access or use them.
                <br /> <br />
                2.2 You have the following rights: <br />
                <ol type="i">
                  <li>
                    right to request from Us access to and rectification or
                    erasure of your Personal Data;
                  </li>
                  <li>
                    right to restrict processing or object to processing of your
                    Personal Data;
                  </li>
                  <li>right to data portability;</li>
                  <li>
                    right to withdraw consent at any time, without affecting the
                    lawfulness of processing based on consent before its
                    withdrawal;
                  </li>
                  <li>
                    right to lodge a complaint with the relevant authority.
                  </li>
                </ol>
                2.3 In the event that you wish to: (i) access your Personal Data
                processed by Us in accordance with this Privacy Statement, or
                (ii) ask any questions at all regarding Our use of your Personal
                Data, or (iii) rectify your Personal Data processed by Us in
                accordance with this Privacy Statement (iv) restrict or object
                to processing of your Personal Data (v) withdraw your consent to
                Our processing of your Personal Data or (vi) request for data
                portability , please reach out{' '}
                <a href="mailto:hello@shoptopup.com">hello@shoptopup.com</a>.
                <br /> <br />
                2.4 We carefully analyze what types of Personal Data We need to
                provide Our Services, and We try to limit the Personal Data We
                collect to only what We really need.
                <br /> <br />
                2.5 Where possible, We delete or anonymize Personal Data when We
                no longer need it. We will retain your Personal Data for the
                duration of Our Services to you and afterwards for as long as is
                necessary and relevant for purposes permitted by applicable laws
                and regulations. Where We no longer need your Personal Data, We
                will dispose of it in a secure manner.
                <br /> <br />
                2.6 If you have created a username, identification code,
                password or any other piece of information as part of Our access
                security measures, you must treat such information as
                confidential, and you must not disclose it to any third party.
                We reserve the right to disable any user identification code or
                password, whether chosen by you or allocated by Us, at any time,
                if in Our opinion you have failed to comply with any of the
                provisions of these conditions. If you know or suspect that
                anyone other than you know your security details, you must
                promptly notify Us at{' '}
                <a href="mailto:hello@shoptopup.com">hello@shoptopup.com</a>
              </span>
            </div>
            <div className="contract-section" id="consent">
              <h5 className="section-title">3. Your Consent</h5>
              <span className="contract-text">
                3.1 Please read this Privacy Statement very carefully. If you do
                not wish to be bound by the provisions of this Privacy
                Statement, you should not access the Website or sign up for Our
                Services. By using the Website, and by signing up for Our
                Services, you hereby confirm that you have read, understand, and
                agree to be bound by the Privacy Statement.
                <br /> <br />
                3.2 Additionally, please note that the option to sign up on this
                Website is intended solely for persons who are eighteen (18)
                years of age or older, and any registration by, use of or access
                to the Services by any person under 18 is unauthorized and in
                violation of this Privacy Statement.
                <br /> <br />
                3.3 You hereby give Us consent to the collection and processing
                of your Personal Data for the purpose of providing the Services.
                You hereby certify that all the information provided by you are
                true and correct and that any misrepresentations or falsity,
                whether intentional or not, therein will be considered as an act
                to defraud Us and Our partners.  You authorize Us to verify and
                investigate the above statements/information as may be required,
                from the references provided and other reasonable sources. For
                this purpose, you hereby waive your rights on the
                confidentiality of client information and expressly consent to
                the processing of any Personal Data and records relating to
                you  that might be obtained from third parties, including
                government agencies, your employer, credit bureaus, business
                associates, and other entities We may deem proper and sufficient
                in the conduct of your business, sensitive or otherwise, for the
                purpose of determining your eligibility for a loan for which you
                are applying. You further agree that when you request for the
                Services or apply for a loan, all supporting documents and any
                other information obtained relative to such application shall be
                used by and communicated to Us, and shall remain Our property
                whether or not your credit score is determined, or the loan is
                granted. For the purpose of providing the Services to you, you
                expressly and unconditionally authorize Us to disclose to any
                bank, partners, affiliate, accredited credit reporting agencies.
                and other financial institution, any Personal Data relating to
                you. 
              </span>
            </div>
            <div className="contract-section" id="data-type">
              <h5 className="section-title">
                4. What Kind Of Personal Data Do We Collect?
              </h5>
              <span className="contract-text">
                4.1 This section details the Personal Data We may collect about
                you. We explain why, and how, We use it in later sections. If
                you decide to create an account or enquire about one of Our
                products or Services, We’ll ask You to provide Us with some
                specific information, for example:{' '}
                <strong>Basic personal details</strong> – such as name,
                surnames, passport photograph, title and date of birth;{' '}
                <strong>Personal contact details</strong> – such as home
                address, delivery address, home phone number, mobile phone
                number, and email address; <strong>Work details</strong> – such
                as job title, department, company name, company address,
                personal information of employees who may act as your agents,
                work email address and office phone number;{' '}
                <strong>Log in details</strong> – such as username and password;{' '}
                <strong>Usage Information and Browsing History</strong> - such
                as usage metrics (including usage rates, occurrences of
                technical errors, diagnostic reports, settings preferences,
                content and advertising interactions; banking details –account
                number, bank verification number. We may also collect additional
                information involving Your opinion of Our products and Services
                and Your preferences regarding other services such as newsletter
                subscription.
              </span>
            </div>
            <div className="contract-section" id="data-source">
              <h5 className="section-title">
                5. What is the Source of Personal Data We Collect?
              </h5>
              <span className="contract-text">
                5.1 We collect Personal Data about you either directly from You,
                from certain third parties (such as your employer or the
                subscriber providing access to Our Services), or from other
                sources (such as publicly available sources) when you <br />
                <ul>
                  <li>Search for, subscribe to Our Services.</li>
                  <li>Use Our Services or otherwise interact with Us.</li>
                  <li>Create or maintain a profile or account with Us.</li>
                  <li>
                    Purchase, use, or otherwise interact with content, products,
                    or services from third party providers who have a
                    relationship with Us.
                  </li>
                  <li>Create, post, or submit user content on Our Services.</li>
                  <li>
                    Register for or attend one of Our events or locations.
                  </li>
                  <li>Request or sign up for information.</li>
                  <li>
                    Communicate with Us by phone, email, chat, in person, or
                    otherwise.
                  </li>
                  <li>
                    Complete a questionnaire, survey, support ticket, or other
                    information request form.
                  </li>
                  <li>
                    When you express an interest in working with Us or apply for
                    a job or position with Us.
                  </li>
                </ul>
                5.2 In some circumstances, We collect Personal Data about you
                from Our partners or from publicly available websites to help Us
                better understand Our audience and enhance the relevance of Our
                content. We collect Personal Data about you from third parties,
                such as: <br />
                <ul>
                  <li>
                    Your organization and others with whom you have a
                    relationship that provide or publish Personal Data related
                    to you, such as from Our customers when they arrange access
                    to Our Services for you or from others when they create,
                    post, or submit user content on Our Services that may
                    include your Personal Data.
                  </li>
                  <li>
                    Professional or industry organizations and certification /
                    licensure agencies that provide or publish Personal Data
                    related to you.
                  </li>
                  <li>
                    Third parties and affiliates who resell or integrate with
                    Our Services.
                  </li>
                  <li>
                    Service providers and business partners who work with Us in
                    relation to Our Services and that We may utilize to deliver
                    certain content, products, or services or to enhance your
                    experience.
                  </li>
                  <li>
                    Marketing, sales generation, and recruiting business
                    partners.
                  </li>
                  <li>Credit bureaus and other similar agencies.</li>
                  <li>
                    Government agencies and others who release or publish public
                    records.
                  </li>
                  <li>
                    Other publicly or generally available sources, such as
                    social media sites, public and online websites, open
                    databases, the corporate affairs commission, and data in the
                    public domain.
                  </li>
                </ul>
                5.3 We use cookies (and similar technologies) and analytics
                tools to collect information about You. This information can be
                used to improve the performance of the site, make advertising
                more relevant and enhance your user experience, such as{' '}
                <strong>Your location</strong> – We use cookies to display
                information that is most relevant to your location.{' '}
                <strong>Your usage</strong> – We use cookies to understand how
                Our customers use Our Website and interact with Our
                communications. For example, We use technology on Our Website,
                which records user movements, including page scrolling, clicks
                and text entered. (It does not record payment details.) This
                helps Us to identify usability issues and improve the assistance
                We can provide to users and is also used for aggregated and
                statistical reporting purposes. <strong>Your device</strong> –
                We use cookies to understand what type of device you are using
                to show you the best version of the site. For instance, if you
                visit Our Website on a mobile device, Our technology will ensure
                that you see a version that is optimized for mobile viewing and
                interaction.
                <strong>Your engagement with advertisements</strong> – We use
                cookies to understand what advertisements you have been shown,
                or clicked on, to bill Our advertising partners, and to present
                you with advertisements that are more relevant to you.
                <br /> <br />
                5.4 Cookies are small files placed on your computer’s hard drive
                that enables the Website to identify your computer as you view
                different pages. Cookies allow websites and applications to
                store your preferences in order to present contents, options or
                functions that are specific to you. Like most interactive
                websites, Our Website uses cookies to enable the tracking of
                your activity for the duration of a session.
              </span>
            </div>
            <div className="contract-section" id="law">
              <h5 className="section-title">6. Lawful Basis for Processing</h5>
              <span className="contract-text">
                <span className="key">
                  6.1 We only process Personal Data where We have a lawful basis
                  for doing so, such as the following:
                </span>
                <br /> <br />
                6.1.1 <strong>User consent</strong> – This is where you have
                given Us explicit permission to process Personal Data for a
                given purpose. For example, if you create a profile on Our
                Website, We may additionally ask you to consent to Us processing
                your Personal Data.
                <br /> <br />
                6.1.2 <strong>Contractual necessity</strong> – This is where We
                have to process Personal Data to meet Our contractual
                obligations.
                <br /> <br />
                6.1.3 <strong>Legal obligation</strong> – This is where We have
                to process Personal Data in order to comply with the law.
                <br /> <br />
                6.1.4 <strong>Protection of vital interests</strong> – This is
                where We are constrained to process your Personal Data in order
                to protect your interests or those of another person, such as to
                prevent identity theft.
                <br /> <br />
                6.1.5 <strong>Public interest</strong> – This is where there is
                an overriding public interest or We have been vested with an
                official public mandate to take certain action in the interest
                of the public.
                <br /> <br />
                6.1.6 <strong>Legitimate/Commercial interest</strong>. This is
                where We process Personal Data in order to protect Our
                legitimate/commercial interest.
              </span>
            </div>
            <div className="contract-section" id="data-usage">
              <h5 className="section-title">
                7. How Do We Use your Personal Data?
              </h5>
              <span className="contract-text">
                7.1 TradeDepot acts as a Data Controller when using your
                Personal Data in providing you with Services. We use Personal
                Data about you in the following ways: <br />
                <ol type="i">
                  <li>to provide you access to Our Services;</li>
                  <li>
                    to improve and maintain performance of the Website, by
                    ensuring that the content on the Website is presented in the
                    most effective manner for you;
                  </li>
                  <li>
                    to provide you with data about services that you request
                    from Us or which We feel may interest you, where you have
                    consented to be contacted for such purposes;
                  </li>
                  <li>for statistical analysis or market research;</li>
                  <li>to develop and improve Our Services;</li>
                  <li>to update Our records;</li>
                  <li>
                    to detect, investigate, prevent, or tackle illegal
                    activities, fraud, or situations involving potential threats
                    to the rights, property, or personal safety of Data Subject;
                  </li>
                  <li>
                    to ensure Our compliance with Our policies, terms, and other
                    applicable laws and regulations;
                  </li>
                  <li>
                    to carry out Our obligations arising from and exercise Our
                    rights under any agreements between you and Us;
                  </li>
                  <li>
                    to allow you to participate in interactive features of our
                    Services, when you choose to do so;
                  </li>
                  <li>to notify you about changes to Our Services;</li>
                  <li>to provide you with customer service;</li>
                  <li>
                    for any legitimate business Interest, including recovery of
                    loans that are in default;
                  </li>
                  <li>
                    recruitment – If you apply for a role via one of Our sites,
                    We will process your information in order to facilitate the
                    application process;
                  </li>
                  <li>
                    to process and assess your application for employment with
                    Us; and
                  </li>
                  <li>
                    in any other way that you have specifically consented to.
                  </li>
                </ol>
              </span>
            </div>
            <div className="contract-section" id="data-sharing">
              <h5 className="section-title">
                8. Who We share your Personal Data with?
              </h5>
              <span className="contract-text">
                <span className="key">
                  8.1 We do not share Personal Data with unaffiliated third
                  parties, except as necessary for Our legitimate business
                  interest, to provide you with Our Services, and/or as required
                  or permitted by law or professional standards and generally to
                  facilitate the running of Our business or to provide specific
                  services you have requested. We would only transfer Personal
                  Data to the following categories or situations, when they meet
                  Our strict standards on the processing of data and security.
                  We only share Personal Data that is necessary for them to
                  provide their services. TradeDepot will not transfer your
                  Personal Data to any third parties for their own direct
                  marketing use except as stated herein. Commonly, We may share
                  your Personal Data with:
                </span>
                <br /> <br />
                8.1.1 <strong>Service providers</strong> – We engage service
                providers who help to support Our business and improve Our
                products. These service providers may include payment
                processors, customer service agencies, credit bureaus, hosts,
                organizers and sponsors of Our events; organizations that host
                Our Website or databases and providers of online surveys. We
                have contracts in place with these data processors and they can
                only use your Personal Data under Our instruction.
                <br /> <br />
                8.1.2 <strong>Advertisers</strong> – We, and Our advertising
                partners, use cookies to collect Personal Data and serve you
                with advertisements that We believe are relevant to you and your
                interests.
                <br /> <br />
                8.1.3 <strong>Audits</strong> - disclosures of Personal Data may
                also be needed for data privacy, financial or security audits
                and/or to investigate or respond to a complaint or security
                threat.
                <br /> <br />
                8.1.4 <strong>Legal processes and successors in title</strong> –
                We, and Our suppliers, may also disclose your Personal Data to
                comply with applicable laws, court orders or other valid legal
                processes, and to enforce or apply Our terms of use, any
                subscription agreement, or any of Our other rights. We may
                transfer or disclose your Personal Data to any entity which
                takes over or acquires the relevant TradeDepot company. We also
                share aggregated, non-identifiable information with third
                parties. Before sharing this information, We ensure that it
                cannot identify you as an individual. For example, We may share
                information about the size and growth of Our customer base with
                investors.
                <br /> <br />
                8.1.5{' '}
                <strong>Other third parties/relatives or acquaintances</strong>:
                We may disclose your Personal Data to other third parties in
                order to compel you to meet your contractual obligations to Us.
                For example, in the event of a default in the repayment of a
                loan advanced to you, We may share your Personal Data with debt
                recovery agencies, debt collectors, your acquaintances or within
                your personal network.
              </span>
            </div>
            <div className="contract-section" id="data-period">
              <h5 className="section-title">
                9. How long We retain your Personal Data?
              </h5>
              <span className="contract-text">
                9.1 We retain your Personal Data for as long as your account is
                active and therefore open; this allows you to continue accessing
                Our content, products and services.
                <br /> <br />
                9.2 If you become inactive and are not a subscriber, We retain
                your Personal Data only for the period within which it is
                reasonably needed and may delete elements of your Personal Data
                associated with your account. We will normally warn you if your
                access is going to be withdrawn and give you the opportunity to
                retain your account.
                <br /> <br />
                9.3 Where We don’t need to keep all of your Personal Data in
                full, We may obfuscate or aggregate it, for example, web
                activity logs and survey responses. This is to ensure that We do
                not retain your Personal Data for any longer than is necessary.
                <br /> <br />
                <span className="key">
                  9.4 It is sometimes necessary for Us to keep your Personal
                  Data for longer periods of time, for example:
                </span>
                <br /> <br />
                9.4.1 If there is a statutory requirement to retain it;
                <br /> <br />
                9.4.2 If We require the Personal Data for legal reasons or there
                is a legitimate business need for Us to retain it;
                <br /> <br />
                9.4.3 To ensure We do not contact you if you have asked Us not
                to.
              </span>
            </div>
            <div className="contract-section" id="data-storage">
              <h5 className="section-title">
                10. How Do We Secure And Store Personal Data?
              </h5>
              <span className="contract-text">
                10.1 We employ all reasonable efforts to keep your Personal Data
                secure by taking appropriate technical and organizational
                measures against its unauthorised or unlawful processing and
                against its accidental loss, destruction or damage. We store and
                process your Personal Data on Our computers in Nigeria. Where We
                need to transfer your Personal Data to another country, We would
                take reasonable steps to ensure that such country has an
                adequate data protection law. You hereby consent to the transfer
                of your Personal Data to countries which may not be on the white
                list specified by the NDPR where such transfers become expedient
                for us. We protect your Personal Data using physical, technical,
                and administrative security measures to reduce the risks of
                loss, misuse, unauthorized access, disclosure and alteration.
                Some of the safeguards We use are firewalls and data encryption,
                physical access controls to Our data centers, and information
                access authorization controls.
              </span>
            </div>
            <div className="contract-section" id="extra-data">
              <h5 className="section-title">
                11. What Happens When You Provide Us With Information About
                Others?
              </h5>
              <span className="contract-text">
                11.1 If you provide Us with Personal Data about another
                individual, you are responsible for ensuring that you comply
                with any obligation and consent obligations under applicable
                data protection laws in relation to such disclosure. Insofar as
                required by applicable data protection laws, you must ensure
                that you have provided the required notices and have obtained
                the individual’s explicit consent to provide Us with the
                information and that you explain to them how We collect, use,
                disclose and retain their Personal Data or direct them to read
                Our Privacy Statement
              </span>
            </div>
            <div className="contract-section" id="extra-contact">
              <h5 className="section-title">
                12. How Do We Collect And Store Contact Information?
              </h5>
              <span className="contract-text">
                12.1 When you choose to upload your contact list, we may collect
                only the phone numbers and do not store any contact information
                contained in your list. This information will be used solely for
                the purpose of providing you with the features and services of
                our platform, including the ability to send airtime to a
                contact.
              </span>
            </div>
            <div className="contract-section" id="general-inquiry">
              <span className="contract-text">
                13. For general enquiries regarding this Privacy Statement, or
                to make a request or complain about how We process Personal
                Data, you may contact Us at{' '}
                <a href="mailto:hello@shoptopup.com">hello@shoptopup.com</a>.
              </span>
            </div>
          </div>
          <div className="col-md-3 list">
            <ul className="terms-item">
              <li>
                <a
                  href="#introduction"
                  onClick={() => setActive('introduction')}
                  className={activeId === 'introduction' ? 'active' : ''}
                >
                  Introduction
                </a>
              </li>
              <li>
                <a
                  href="#privacy-rights"
                  onClick={() => setActive('privacy-rights')}
                  className={activeId === 'privacy-rights' ? 'active' : ''}
                >
                  Your Privacy Rights
                </a>
              </li>
              <li>
                <a
                  href="#consent"
                  onClick={() => setActive('consent')}
                  className={activeId === 'consent' ? 'active' : ''}
                >
                  Your Consent
                </a>
              </li>
              <li>
                <a
                  href="#data-type"
                  onClick={() => setActive('data-type')}
                  className={activeId === 'data-type' ? 'active' : ''}
                >
                  What Kind Of Personal Data Do We Collect?
                </a>
              </li>
              <li>
                <a
                  href="#data-source"
                  onClick={() => setActive('data-source')}
                  className={activeId === 'data-source' ? 'active' : ''}
                >
                  What is the Source of Personal Data We Collect?
                </a>
              </li>
              <li>
                <a
                  href="#law"
                  onClick={() => setActive('law')}
                  className={activeId === 'law' ? 'active' : ''}
                >
                  Lawful Basis for Processing
                </a>
              </li>
              <li>
                <a
                  href="#data-usage"
                  onClick={() => setActive('data-usage')}
                  className={activeId === 'data-usage' ? 'active' : ''}
                >
                  How Do We Use your Personal Data?
                </a>
              </li>
              <li>
                <a
                  href="#data-sharing"
                  onClick={() => setActive('data-sharing')}
                  className={activeId === 'data-sharing' ? 'active' : ''}
                >
                  Who We share your Personal Data with?
                </a>
              </li>
              <li>
                <a
                  href="#data-period"
                  onClick={() => setActive('data-period')}
                  className={activeId === 'data-period' ? 'active' : ''}
                >
                  How long We retain your Personal Data?
                </a>
              </li>
              <li>
                <a
                  href="#data-storage"
                  onClick={() => setActive('data-storage')}
                  className={activeId === 'data-storage' ? 'active' : ''}
                >
                  How Do We Secure And Store Personal Data?
                </a>
              </li>
              <li>
                <a
                  href="#extra-data"
                  onClick={() => setActive('extra-data')}
                  className={activeId === 'extra-data' ? 'active' : ''}
                >
                  What Happens When You Provide Us With Information About
                  Others?
                </a>
              </li>
              <li>
                <a
                  href="#extra-contact"
                  onClick={() => setActive('extra-contact')}
                  className={activeId === 'extra-contact' ? 'active' : ''}
                >
                  How Do We Collect And Store Contact Information?
                </a>
              </li>
              <li>
                <a
                  href="#general-inquiry"
                  onClick={() => setActive('general-inquiry')}
                  className={activeId === 'general-inquiry' ? 'active' : ''}
                >
                  General Inquiry
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
